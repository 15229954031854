import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LogsPopup.scss";
import { useAdminMessagesContext } from "../../../context/AdminMessagesContext";

const LogsPopup = ({ onClose, conversationId = null }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adminUsernameFilter, setAdminUsernameFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [conversationIdFilter, setConversationIdFilter] = useState(conversationId || "");
  const [actionFilter, setActionFilter] = useState("");
  const [logLimit, setLogLimit] = useState(25);
  const { selectConversationById } = useAdminMessagesContext();
  const [deletedConversations, setDeletedConversations] = useState([]);

  const handleSelectConversation = async (conversationId) => {
    const success = await selectConversationById(conversationId);
    if (success) {
      onClose();
    } else {
      // Mark the conversation ID as deleted
      setDeletedConversations((prev) => [...prev, conversationId]);
    }
  };

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("admin-token");
      const apiUrl = new URL("/api/config/logs", process.env.REACT_APP_API_URL).toString();

      const params = { limit: logLimit };
      if (adminUsernameFilter) params.adminUsername = adminUsernameFilter;
      if (startDateFilter) params.startDate = startDateFilter;
      if (endDateFilter) params.endDate = endDateFilter;
      if (conversationIdFilter) params.conversationId = conversationIdFilter;
      if (actionFilter) params.action = actionFilter;

      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });

      if (response.data.success) {
        setLogs(response.data.logs);
      } else {
        setLogs([]);
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [logLimit]);

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchLogs();
  };

  return (
    <div className="logs-popup">
      <div className="logs-popup__overlay" onClick={onClose}></div>
      <div className="logs-popup__content">
        {!conversationId ? <h2>Relatório de Atividade</h2> : <h2>Relatório de Conversa</h2>}

        <form className="logs-popup__filters" onSubmit={handleFilterSubmit}>
          {!conversationId && (
            <>
              <input type="text" placeholder="Admin Username" value={adminUsernameFilter} onChange={(e) => setAdminUsernameFilter(e.target.value)} />
              <input type="date" placeholder="Start Date" value={startDateFilter} onChange={(e) => setStartDateFilter(e.target.value)} />
              <input type="date" placeholder="End Date" value={endDateFilter} onChange={(e) => setEndDateFilter(e.target.value)} />
              <input type="text" placeholder="Conversation ID" value={conversationIdFilter} onChange={(e) => setConversationIdFilter(e.target.value)} />
              <input type="text" placeholder="Ação" value={actionFilter} onChange={(e) => setActionFilter(e.target.value)} />
              <button type="submit">Filtrar</button>
            </>
          )}
          <select value={logLimit} onChange={(e) => setLogLimit(Number(e.target.value))}>
            <option value={25}>25 logs</option>
            <option value={50}>50 logs</option>
            <option value={100}>100 logs</option>
          </select>
        </form>

        {loading ? (
          <p>Loading logs...</p>
        ) : logs.length > 0 ? (
          <div className="logs-popup__table-container">
            <table className="logs-popup__table">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Admin / User</th>
                  <th>Ação</th>
                  <th>ID Conversa</th>
                  <th>Descrição</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log) => (
                  <tr key={log._id}>
                    <td>{new Date(log.timestamp).toLocaleString()}</td>
                    <td>
                      {log.credential ? `(${log.credential}) ` : ""}
                      {log.adminUsername ? log.adminUsername : log.username}
                    </td>
                    <td>{log.action}</td>
                    <td>
                      {log.conversationId ? (
                        log.isDeleted || deletedConversations.includes(log.conversationId) ? (
                          <span className="deleted-conversation">{log.conversationId}</span>
                        ) : (
                          <button onClick={() => handleSelectConversation(log.conversationId)} className="conversation-id-button">
                            {log.conversationId}
                          </button>
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>{log.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>Sem relatórios de atividade.</p>
        )}
      </div>
    </div>
  );
};

export default LogsPopup;
