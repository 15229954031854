//******************************************************************************************/
// Component to display a single conversation in the admin panel, showing the latest
// message, speaker, and conversation status based on the conversation's override state.
//******************************************************************************************/

import React from "react";
import "./AdminConversation.scss";
import { useAuthContext } from "../../../context/AuthContext";
import { SPEAKER_NAME_CONSTANTS, CONVERSATION_STATUS_CONSTANTS } from "../../../utils/constants";

const AdminConversation = ({ conversation, onClick, isActive }) => {
  // Retrieve the current authenticated admin user from the AuthContext
  const { authUser } = useAuthContext();

  //Extract messages from the conversation
  const messages = conversation?.messages || [];

  // Determine the text of the last message to display
  const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
  const lastMessageText = lastMessage
    ? lastMessage.message
    : conversation.isOverridden && !conversation.overriddenByAdminId
    ? CONVERSATION_STATUS_CONSTANTS.WAITING_FOR_HUMAN_AGENT
    : CONVERSATION_STATUS_CONSTANTS.NO_MESSAGES_TO_DISPLAY; // Shouldn't happen but just in case
  const lastMessageTime = lastMessage ? new Date(lastMessage.createdAt).toLocaleString() : new Date(conversation.lastActivity).toLocaleString(); // Display last activity if no message is present

  // Determine who the last speaker was in the conversation, defaulting to Bot
  let lastSpeaker = SPEAKER_NAME_CONSTANTS.BOT;
  if (lastMessage) {
    if (lastMessage.senderModel === "Admin") {
      lastSpeaker = lastMessage.senderId?.username || SPEAKER_NAME_CONSTANTS.ADMIN;
    } else if (lastMessage.senderModel === "ChatbotUser") {
      lastSpeaker = SPEAKER_NAME_CONSTANTS.CITIZEN;
    }
  } else if (conversation.isOverridden && !conversation.overriddenByAdminId) {
    lastSpeaker = SPEAKER_NAME_CONSTANTS.SYSTEM;
  }

  // Format the message to display in the conversation preview
  const formattedMessage = `${lastSpeaker}: ${lastMessageText}`;

  // Determine the status and color based on the conversation's override state
  let status = CONVERSATION_STATUS_CONSTANTS.USER_TALKING_TO_BOT;
  let statusColor = "gray";

  if (conversation.isUnderReview) {
    status = CONVERSATION_STATUS_CONSTANTS.UNDER_REVIEW;
    statusColor = "dark-yellow";
  } else if (conversation.closed) {
    status = CONVERSATION_STATUS_CONSTANTS.CLOSED;
    statusColor = "black";
  } else if (conversation.isOverridden && conversation.overriddenByAdminId) {
    if (authUser && conversation.overriddenByAdminId === authUser._id) {
      status = CONVERSATION_STATUS_CONSTANTS.TALKING_WITH_YOU;
      statusColor = "blue";
    } else {
      status = CONVERSATION_STATUS_CONSTANTS.TALKING_WITH_OTHER_ADMIN;
      statusColor = "grayish-green";
    }
  } else if (conversation.isOverridden) {
    status = CONVERSATION_STATUS_CONSTANTS.WAITING_FOR_HUMAN_AGENT;
    statusColor = "red";
  }

  // Determine if there are new messages for the current admin
  const hasNewMessages = !conversation.adminSeen;

  // Function to process message text for bold and links
  const renderMessageText = (text) => {
    const elements = [];
    let lastIndex = 0;

    // Combined regex to match:
    // 1. Bold text: **text** or __text__
    // 2. Markdown links: [text](url)
    // 3. Plain URLs: http:// or https:// followed by non-space characters
    const regex = /(\*\*|__)(.*?)\1|\[([^\]]+)\]\((https?:\/\/[^\s]+)\)|(https?:\/\/[^\s]+)/g;

    let match;
    while ((match = regex.exec(text)) !== null) {
      const index = match.index;

      // Add text before the current match
      if (index > lastIndex) {
        elements.push(text.slice(lastIndex, index));
      }

      if (match[1] && match[2]) {
        // **Bold Text** or __Bold Text__
        elements.push(<strong key={`bold-${index}`}>{match[2]}</strong>);
      } else if (match[3] && match[4]) {
        // [Text](URL) -> Display URL as plain text
        const url = match[4];
        elements.push(
          <span key={`markdown-link-${index}`} className="admin-conversation__url">
            {url}
          </span>
        );
      } else if (match[5]) {
        // Plain URL -> Display as plain text
        const url = match[5];
        elements.push(
          <span key={`plain-link-${index}`} className="admin-conversation__url">
            {url}
          </span>
        );
      }

      lastIndex = regex.lastIndex;
    }

    // Add any remaining text after the last match
    if (lastIndex < text.length) {
      elements.push(text.slice(lastIndex));
    }

    return elements;
  };

  return (
    <div className={`admin-conversation admin-conversation--${statusColor} ${isActive ? "admin-conversation--active" : ""}`} onClick={onClick}>
      <div className="admin-conversation__header">
        <p className={`admin-conversation__status admin-conversation__status--${statusColor}`}>{status}</p>
        {hasNewMessages && <span className="admin-conversation__new-messages">NÃO LIDO</span>}
      </div>
      <div className="admin-conversation__footer">
        <p className="admin-conversation__time">{lastMessageTime}</p>
      </div>
      <div className="admin-conversation__body">
        <p className="admin-conversation__message">{renderMessageText(formattedMessage)}</p>
      </div>
    </div>
  );
};

export default AdminConversation;
