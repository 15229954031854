//******************************************************************************************
// Message Component: Displays a single message bubble in the chat with different styling
// based on sender type (user, admin, bot, or warning). Uses link parsing to convert URLs
// in the message text into clickable links.
//******************************************************************************************

import React from "react";
import LinkifyIt from "linkify-it";
import tlds from "tlds";
import "./Message.scss";

const linkify = new LinkifyIt();
linkify.tlds(tlds);

const Message = ({ text = "", time, senderModel, isWarning }) => {
  let messageClass = "";

  if (isWarning) {
    messageClass = "message--warning";
  } else if (senderModel === "ChatbotUser") {
    messageClass = "message--user";
  } else if (senderModel === "Admin") {
    messageClass = "message--admin";
  } else if (senderModel === "Bot" || senderModel === "SYSTEM") {
    messageClass = "message--bot";
  }

  const cleanMarkdownLinks = (inputText) => {
    const markdownLinkRegex = /\[([^\]]+)\]\(([^ ")]+)(\s+"[^"]*")?\)/g;

    return inputText.replace(markdownLinkRegex, (match, linkText, url, title) => {
      if (title) {
        return `[${linkText}](${url})`;
      }
      return match;
    });
  };

  const renderMessageText = (inputText) => {
    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const boldRegex = /\*\*([^\*]+)\*\*/g; // Match bold text (**text**)

    const elements = [];
    let lastIndex = 0;

    // Process markdown links
    let match;
    while ((match = markdownLinkRegex.exec(inputText)) !== null) {
      const { index } = match;
      const [fullMatch, linkText, url] = match;

      // Add text before the markdown link
      if (index > lastIndex) {
        elements.push(inputText.slice(lastIndex, index));
      }

      // Add the markdown link
      elements.push(
        <a key={`markdown-link-${index}`} href={url} target="_blank" rel="noopener noreferrer" className="message__link">
          {linkText}
        </a>
      );

      lastIndex = index + fullMatch.length;
    }

    // Process bold text
    let boldMatch;
    while ((boldMatch = boldRegex.exec(inputText)) !== null) {
      const { index } = boldMatch;
      const [fullMatch, boldText] = boldMatch;

      // Add text before the bold markdown
      if (index > lastIndex) {
        elements.push(inputText.slice(lastIndex, index));
      }

      // Add the bold text
      elements.push(<strong key={`bold-${index}`}>{boldText}</strong>);

      lastIndex = index + fullMatch.length;
    }

    // Add any remaining text after the last match
    if (lastIndex < inputText.length) {
      elements.push(inputText.slice(lastIndex));
    }

    // Now process and linkify any plain URLs
    const finalElements = [];
    elements.forEach((element, index) => {
      if (typeof element === "string") {
        const matches = linkify.match(element);
        if (matches) {
          let textLastIndex = 0;
          matches.forEach((linkMatch, linkIndex) => {
            const { index: linkIndexStart, lastIndex: linkIndexEnd, url: linkUrl } = linkMatch;
            // Add text before the link
            if (linkIndexStart > textLastIndex) {
              finalElements.push(element.slice(textLastIndex, linkIndexStart));
            }
            // Add the link
            finalElements.push(
              <a key={`link-${index}-${linkIndex}`} href={linkUrl} target="_blank" rel="noopener noreferrer" className="message__link">
                {linkUrl}
              </a>
            );
            textLastIndex = linkIndexEnd;
          });
          // Add remaining text
          if (textLastIndex < element.length) {
            finalElements.push(element.slice(textLastIndex));
          }
        } else {
          finalElements.push(element);
        }
      } else {
        finalElements.push(element);
      }
    });

    return finalElements;
  };

  const cleanedText = cleanMarkdownLinks(text);

  return (
    <div className={`message ${messageClass}`}>
      <div className="message__bubble">
        <p className="message__text">{renderMessageText(cleanedText)}</p>
        {!isWarning ? <p className="message__info">{time}</p> : null}
      </div>
    </div>
  );
};

export default Message;
