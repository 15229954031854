//******************************************************************************************
// AdminConversations Component: Renders a list of conversations for the admin, sorted by
// custom priority rules. Allows selecting a conversation and automatically scrolls to the
// active conversation.
//******************************************************************************************

import React, { useState, useEffect, useRef, useMemo } from "react";
import AdminConversation from "./AdminConversation";
import "./AdminConversations.scss";
import { useAuthContext } from "../../../context/AuthContext";
import { useAdminMessagesContext } from "../../../context/AdminMessagesContext";

const AdminConversations = ({ onSelectConversation, selectedConversationId }) => {
  const { conversations = [], loading, selectedConversation, canViewClosed } = useAdminMessagesContext();
  const { authUser } = useAuthContext();
  const listRef = useRef(null);

  // Checkbox filter state
  const [filters, setFilters] = useState({
    talkingWithOther: false,
    talkingWithBot: false,
    closed: false,
  });

  // Function to handle checkbox toggle
  const handleFilterChange = (filterKey) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: !prevFilters[filterKey],
    }));
  };

  // Memoize filtered conversations based on the checkboxes
  const filteredConversations = useMemo(() => {
    return conversations.filter((conversation) => {
      const isClosed = filters.closed && conversation.closed;
      const isTalkingWithOther =
        filters.talkingWithOther &&
        conversation.isOverridden &&
        conversation.overriddenByAdminId !== authUser?._id &&
        conversation.overriddenByAdminId !== null &&
        !conversation.closed;

      const isTalkingWithBot = filters.talkingWithBot && !conversation.isOverridden && !conversation.closed;

      // Include conversations that match any selected filter
      const matchesFilter = isTalkingWithOther || isTalkingWithBot || isClosed;

      // Always include conversations not covered by the filters
      const isAlwaysVisible =
        conversation.isUnderReview || // Under Review
        (conversation.isOverridden &&
          (conversation.overriddenByAdminId === null || conversation.overriddenByAdminId === authUser?._id) &&
          !conversation.closed);

      return matchesFilter || isAlwaysVisible;
    });
  }, [filters, conversations, authUser]);

  // Memoize sorted conversations
  const sortedConversations = useMemo(() => {
    const sorted = [...filteredConversations].sort((a, b) => {
      const authUserId = authUser?._id;

      // Show conversations under review first
      if (a.isUnderReview && !b.isUnderReview) return -1;
      if (!a.isUnderReview && b.isUnderReview) return 1;

      // Closed conversations appear last
      if (a.closed && b.closed) {
        return new Date(b.lastActivity) - new Date(a.lastActivity);
      }
      if (a.closed && !b.closed) return 1;
      if (!a.closed && b.closed) return -1;

      // Prioritize conversations overridden by the current admin
      if (a.overriddenByAdminId === authUserId && b.overriddenByAdminId !== authUserId) {
        return -1;
      }
      if (b.overriddenByAdminId === authUserId && a.overriddenByAdminId !== authUserId) {
        return 1;
      }

      // Within the same overridden status by the current admin, sort by last activity
      if (a.overriddenByAdminId === authUserId && b.overriddenByAdminId === authUserId) {
        return new Date(b.lastActivity) - new Date(a.lastActivity); // Recent first
      }

      // If only one is overridden (not by current admin), show the one that isn't overridden
      if (a.isOverridden && !a.overriddenByAdminId && !(b.isOverridden && !b.overriddenByAdminId)) {
        return -1;
      }
      if (b.isOverridden && !b.overriddenByAdminId && !(a.isOverridden && !a.isOverridden)) {
        return 1;
      }

      // Fallback: Default to sorting by last activity (most recent first)
      return new Date(b.lastActivity) - new Date(a.lastActivity);
    });

    return sorted;
  }, [filteredConversations, authUser?._id]);

  // useEffect to automatically scroll to the active conversation whenever sortedConversations changes
  useEffect(() => {
    const scrollToActiveConversation = () => {
      const activeElement = document.getElementById(`conversation-${selectedConversation?._id}`);

      if (activeElement && listRef.current) {
        // Use scrollIntoView as a first attempt
        activeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    };

    // Scroll every time sortedConversations updates
    if (sortedConversations.length > 0) {
      setTimeout(() => {
        scrollToActiveConversation();
      }, 500); // Slight delay to ensure render is complete
    }
  }, [sortedConversations]);

  return (
    <div className="admin-conversations">
      <h2>Filtros:</h2>
      <div className="admin-conversations__filters">
        <label>
          <input type="checkbox" checked={filters.talkingWithOther} onChange={() => handleFilterChange("talkingWithOther")} />A Falar com Outro User
        </label>
        <label>
          <input type="checkbox" checked={filters.talkingWithBot} onChange={() => handleFilterChange("talkingWithBot")} />A Falar com um Bot
        </label>
        {(canViewClosed || authUser?.isSuperAdmin) && (
          <label>
            <input type="checkbox" checked={filters.closed} onChange={() => handleFilterChange("closed")} />
            Conversas Fechadas
          </label>
        )}
      </div>

      <div className="admin-conversations__list" ref={listRef}>
        {loading ? (
          <p>Loading conversations...</p>
        ) : filteredConversations.length > 0 ? (
          sortedConversations.map((conversation) => (
            <AdminConversation
              key={conversation._id}
              conversation={conversation}
              onClick={() => onSelectConversation(conversation)}
              isActive={selectedConversationId === conversation._id}
              id={`conversation-${conversation._id}`}
            />
          ))
        ) : (
          <p className="admin-conversations__no-results">Nenhuma conversa Disponivel.</p>
        )}
      </div>
    </div>
  );
};

export default AdminConversations;
