//******************************************************************************************
// UserManagementPopup Component: Provides admin user management functionality, including
// admin list retrieval, role toggling, deletion, and settings updates.
//******************************************************************************************

import React, { useState, useEffect } from "react";
import "./UserManagementPopup.scss";
import axios from "axios";
import { useAuthContext } from "../../../context/AuthContext";
import { FiUser, FiUserCheck, FiTrash2, FiKey, FiArrowUp, FiArrowDown } from "react-icons/fi";
const UserManagementPopup = ({ onClose }) => {
  const { authUser } = useAuthContext();
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmationState, setConfirmationState] = useState({});
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newAdmin, setNewAdmin] = useState({ username: "", password: "", confirmPassword: "" });
  const [passwordInputs, setPasswordInputs] = useState({});

  // Fetch admins when the component mounts or the authenticated user changes
  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const token = localStorage.getItem("admin-token");
        if (!token) {
          console.error("No admin token found.");
          return;
        }

        const apiUrl = new URL("/api/config/admins", process.env.REACT_APP_API_URL).toString();
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          const filteredAdmins = response.data.admins
            .filter((admin) => admin.username !== "bot" && admin._id !== authUser._id)
            .map((admin) => ({
              ...admin,
              canManageFAQ: admin.canManageFAQ || false,
            }));
          setAdmins(filteredAdmins);
        }
      } catch (error) {
        console.error("Error fetching admins:", error);
      }
    };

    fetchAdmins();
  }, [authUser]);

  // Update admin settings such as permissions
  const handleUpdateAdminSettings = async (adminId, settings) => {
    try {
      const token = localStorage.getItem("admin-token");
      const apiUrl = new URL(`/api/config/admins/${adminId}/settings`, process.env.REACT_APP_API_URL).toString();

      const response = await axios.put(apiUrl, settings, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setAdmins((prevAdmins) => prevAdmins.map((admin) => (admin._id === adminId ? { ...admin, ...settings } : admin)));
        setMessage("Admin settings updated successfully.");
      }
    } catch (error) {
      console.error("Error updating admin settings:", error);
      setMessage("Failed to update admin settings.");
    }
  };

  // Handle countdown logic and toggle role actions
  const startCountdown = (adminId, actionType) => {
    setConfirmationState((prevState) => ({
      ...prevState,
      [adminId]: { ...prevState[adminId], countdown: 3, actionType, visible: true, confirmed: false },
    }));

    const countdownInterval = setInterval(() => {
      setConfirmationState((prevState) => {
        const currentCountdown = prevState[adminId]?.countdown || 0;
        if (currentCountdown <= 1) {
          clearInterval(countdownInterval);
          return {
            ...prevState,
            [adminId]: { ...prevState[adminId], countdown: 0, visible: true, confirmed: true, step: "confirm" },
          };
        }
        return {
          ...prevState,
          [adminId]: { ...prevState[adminId], countdown: currentCountdown - 1 },
        };
      });
    }, 1000);
  };

  const handleToggleAdminRole = (adminId) => {
    const currentAdmin = admins.find((admin) => admin._id === adminId);
    const newRole = !currentAdmin.isSuperAdmin;

    if (confirmationState[adminId]?.confirmed) {
      confirmToggleRole(adminId, newRole);
    } else {
      startCountdown(adminId, newRole ? "promote" : "demote");
    }
  };

  const handleDeleteAdmin = (adminId) => {
    if (confirmationState[adminId]?.confirmed) {
      confirmDeleteAdmin(adminId);
    } else {
      startCountdown(adminId, "delete");
    }
  };

  const handleForcePassword = (adminId) => {
    setConfirmationState((prevState) => ({
      ...prevState,
      [adminId]: { actionType: "forcePassword", step: "input" },
    }));
  };

  const handleChangePassword = (adminId) => {
    const newPassword = passwordInputs[adminId];
    if (!newPassword) {
      setMessage("Please enter a new password.");
      return;
    }

    // Start the countdown
    startCountdown(adminId, "forcePassword");
    // Set the step to 'countdown'
    setConfirmationState((prevState) => ({
      ...prevState,
      [adminId]: { ...prevState[adminId], step: "countdown" },
    }));
  };

  const confirmToggleRole = async (adminId, newRole) => {
    try {
      const token = localStorage.getItem("admin-token");
      const apiUrl = new URL(`/api/config/admins/${adminId}`, process.env.REACT_APP_API_URL).toString();
      const response = await axios.put(
        apiUrl,
        { isSuperAdmin: newRole },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setAdmins((prevAdmins) => prevAdmins.map((admin) => (admin._id === adminId ? { ...admin, isSuperAdmin: newRole } : admin)));
        setMessage("Alteração efetuada com sucesso");
        setConfirmationState((prevState) => ({ ...prevState, [adminId]: null })); // Reset state
      }
    } catch (error) {
      console.error("Error updating admin role:", error);
      setMessage("Erro ao realizar alteração.");
    }
  };

  // Actual deletion after confirmation
  const confirmDeleteAdmin = async (adminId) => {
    try {
      const token = localStorage.getItem("admin-token");
      const apiUrl = new URL(`/api/config/admins/${adminId}`, process.env.REACT_APP_API_URL).toString();
      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setAdmins((prevAdmins) => prevAdmins.filter((admin) => admin._id !== adminId));
        setMessage("Admin deleted successfully.");
        setConfirmationState((prevState) => ({ ...prevState, [adminId]: null })); // Reset state
      }
    } catch (error) {
      console.error("Error deleting admin:", error);
      setMessage("Failed to delete admin.");
    }
  };

  const confirmForcePassword = async (adminId) => {
    try {
      const newPassword = passwordInputs[adminId];
      if (!newPassword) {
        setMessage("Please enter a new password.");
        return;
      }

      const token = localStorage.getItem("admin-token");
      const apiUrl = new URL(`/api/config/force-password`, process.env.REACT_APP_API_URL).toString();
      const adminUsername = admins.find((admin) => admin._id === adminId).username;
      const response = await axios.put(
        apiUrl,
        { username: adminUsername, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setMessage(`Password for ${adminUsername} updated successfully.`);
        // Reset state
        setConfirmationState((prevState) => ({ ...prevState, [adminId]: null }));
        setPasswordInputs((prevState) => ({ ...prevState, [adminId]: "" }));
      } else {
        setMessage("Failed to update password.");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      setMessage("Failed to update password.");
    }
  };

  const handleCreateInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateAdmin = async () => {
    const { username, password, confirmPassword } = newAdmin;

    if (!username || !password || password !== confirmPassword) {
      setMessage("Invalid input or passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("admin-token");

      const apiUrl = new URL("/api/config/admins", process.env.REACT_APP_API_URL).toString();
      const response = await axios.post(
        apiUrl,
        { username, password, confirmPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setAdmins((prevAdmins) => [...prevAdmins, response.data.admin]);
        setMessage("Admin created successfully.");
        setShowCreateForm(false);
        setNewAdmin({ username: "", password: "", confirmPassword: "" });
      }
    } catch (error) {
      console.error("Error creating admin:", error);
      setMessage("Failed to create admin.");
    }
    setLoading(false);
  };

  const handlePasswordInputChange = (adminId, value) => {
    setPasswordInputs((prevState) => ({ ...prevState, [adminId]: value }));
  };

  return (
    <div className="user-management-popup">
      <div className="user-management-popup__overlay" onClick={onClose}></div>
      <div className="user-management-popup__content">
        <h2>Gestão de Administradores</h2>
        <div className="user-management-popup__message-container">{message && <p className="user-management-popup__message">{message}</p>}</div>

        <button onClick={() => setShowCreateForm(!showCreateForm)} disabled={loading} className="user-management-popup__create-toggle">
          {showCreateForm ? "Cancelar" : "Criar novo administrador"}
        </button>

        {showCreateForm && (
          <div className="user-management-popup__create-form">
            <input type="text" name="username" placeholder="Nome de Utilizador" value={newAdmin.username} onChange={handleCreateInputChange} />
            <input type="password" name="password" placeholder="Palavra-passe" value={newAdmin.password} onChange={handleCreateInputChange} />
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirmar Palavra-passe"
              value={newAdmin.confirmPassword}
              onChange={handleCreateInputChange}
            />
            <button onClick={handleCreateAdmin} disabled={loading} className="user-management-popup__action-button">
              Criar Administrador
            </button>
          </div>
        )}

        <ul className="user-management-popup__list">
          {admins.map((admin) => {
            const isActionInProgress = confirmationState[admin._id]?.confirmed !== true && confirmationState[admin._id]?.countdown !== undefined;

            return (
              <li key={admin._id} className="user-management-popup__item">
                <span className="adminName">
                  {admin.isSuperAdmin ? <FiUserCheck color="purple" /> : <FiUser color="green" />} {admin.username}
                </span>

                <div className="admin_options">
                  <div className="user-specific-settings">
                    <label>
                      Pode fechar conversas:
                      <input
                        type="checkbox"
                        checked={admin.canCloseConversations}
                        onChange={() => handleUpdateAdminSettings(admin._id, { canCloseConversations: !admin.canCloseConversations })}
                        disabled={isActionInProgress}
                      />
                    </label>
                    <label>
                      Pode ver conversas fechadas:
                      <input
                        type="checkbox"
                        checked={admin.canSeeClosed}
                        onChange={() => handleUpdateAdminSettings(admin._id, { canSeeClosed: !admin.canSeeClosed })}
                        disabled={isActionInProgress}
                      />
                    </label>
                    <label>
                      Pode ver conversas assumidas por outros:
                      <input
                        type="checkbox"
                        checked={admin.canSeeOverriddenByOthers}
                        onChange={() => handleUpdateAdminSettings(admin._id, { canSeeOverriddenByOthers: !admin.canSeeOverriddenByOthers })}
                        disabled={isActionInProgress}
                      />
                    </label>
                    <label>
                      Pode gerir FAQ:
                      <input
                        type="checkbox"
                        checked={admin.canManageFAQ}
                        onChange={() => handleUpdateAdminSettings(admin._id, { canManageFAQ: !admin.canManageFAQ })}
                        disabled={isActionInProgress}
                      />
                    </label>
                  </div>

                  {/* Promote/Demote Button */}
                  <button
                    onClick={() => handleToggleAdminRole(admin._id)}
                    disabled={
                      isActionInProgress || loading || (confirmationState[admin._id]?.countdown !== undefined && !confirmationState[admin._id]?.confirmed)
                    }
                    className="user-management-popup__action-button"
                  >
                    {confirmationState[admin._id]?.actionType === (admin.isSuperAdmin ? "demote" : "promote") && confirmationState[admin._id]?.confirmed ? (
                      `Confirmar ${admin.isSuperAdmin ? "Redução" : "Promoção"}`
                    ) : confirmationState[admin._id]?.actionType === (admin.isSuperAdmin ? "demote" : "promote") &&
                      confirmationState[admin._id]?.countdown !== undefined ? (
                      `Confirmar (${confirmationState[admin._id].countdown})`
                    ) : admin.isSuperAdmin ? (
                      <>
                        <FiArrowDown />
                        Reduzir a Admin
                      </>
                    ) : (
                      <>
                        <FiArrowUp />
                        Promover a Super Admin"
                      </>
                    )}
                  </button>

                  {/* Force Password Logic */}
                  {confirmationState[admin._id]?.actionType === "forcePassword" ? (
                    confirmationState[admin._id]?.step === "input" ? (
                      <div className="force-password-container">
                        <input
                          type="password"
                          placeholder="Nova Palavra-passe"
                          value={passwordInputs[admin._id] || ""}
                          onChange={(e) => handlePasswordInputChange(admin._id, e.target.value)}
                          className="force-password-input"
                          disabled={isActionInProgress}
                        />
                        <button
                          onClick={() => handleChangePassword(admin._id)}
                          disabled={loading || isActionInProgress}
                          className="user-management-popup__action-button"
                        >
                          Alterar
                        </button>
                      </div>
                    ) : confirmationState[admin._id]?.step === "countdown" ? (
                      <button disabled className="user-management-popup__action-button">
                        Confirmar ({confirmationState[admin._id].countdown})
                      </button>
                    ) : confirmationState[admin._id]?.step === "confirm" && confirmationState[admin._id]?.confirmed ? (
                      <button
                        onClick={() => confirmForcePassword(admin._id)}
                        disabled={loading || isActionInProgress}
                        className="user-management-popup__action-button"
                      >
                        Confirmar
                      </button>
                    ) : null
                  ) : (
                    <button
                      onClick={() => handleForcePassword(admin._id)}
                      disabled={loading || isActionInProgress}
                      className="user-management-popup__action-button"
                    >
                      <FiKey /> Alterar Palavra-passe
                    </button>
                  )}

                  {/* Delete Button */}
                  <button
                    onClick={() => handleDeleteAdmin(admin._id)}
                    disabled={
                      loading || isActionInProgress || (confirmationState[admin._id]?.countdown !== undefined && !confirmationState[admin._id]?.confirmed)
                    }
                    className="user-management-popup__action-button"
                  >
                    {confirmationState[admin._id]?.actionType === "delete" && confirmationState[admin._id]?.confirmed ? (
                      "Confirmar remoção"
                    ) : confirmationState[admin._id]?.actionType === "delete" && confirmationState[admin._id]?.countdown !== undefined ? (
                      `Confirmar (${confirmationState[admin._id].countdown})`
                    ) : (
                      <>
                        <FiTrash2 /> Remover Admin
                      </>
                    )}
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default UserManagementPopup;
