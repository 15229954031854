//******************************************************************************************
// QuestionAnswerManagementPopup Component: Provides an interface for managing a list of
// question-answer pairs. Supports adding, deleting, and reordering entries with swap
// functionality for prioritizing questions.
//******************************************************************************************

import React, { useState, useEffect } from "react";
import "./QuestionAnswerManagementPopup.scss";
import axios from "axios";
import { FiTrash2, FiArrowUp, FiArrowDown } from "react-icons/fi";

const QuestionAnswerManagementPopup = ({ onClose }) => {
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({ question: "", answer: "" });
  const [message, setMessage] = useState("");

  // Fetch initial entries when the component mounts
  useEffect(() => {
    fetchEntries();
  }, []);

  // fetchEntries: Fetches current Q&A entries from the server
  const fetchEntries = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const apiUrl = new URL("/api/config/questions", baseUrl).toString();

      const token = localStorage.getItem("admin-token");
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.success) {
        setEntries(response.data.config.entries || []);
      } else {
        setMessage("Failed to load entries.");
      }
    } catch (error) {
      console.error("Error fetching Q&A entries:", error);
      setMessage("Error loading entries.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= 140) {
      setNewEntry((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAddEntry = async () => {
    if (!newEntry.question || !newEntry.answer) {
      setMessage("Please fill in both question and answer.");
      return;
    }
    if (entries.length >= 10) {
      setMessage("Maximum of 10 entries allowed.");
      return;
    }

    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const apiUrl = new URL("/api/config/questions/add", baseUrl).toString();

      const token = localStorage.getItem("admin-token");
      const response = await axios.put(apiUrl, { question: newEntry.question, answer: newEntry.answer }, { headers: { Authorization: `Bearer ${token}` } });

      if (response.data.success) {
        setEntries(response.data.config.entries);
        setNewEntry({ question: "", answer: "" });
        setMessage("Entry added successfully.");
      } else {
        setMessage("Failed to add entry.");
      }
    } catch (error) {
      console.error("Error adding entry:", error);
      setMessage("Error adding entry.");
    }
  };

  const handleDeleteEntry = async (index) => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const apiUrl = new URL(`/api/config/questions/${index}`, baseUrl).toString();

      const token = localStorage.getItem("admin-token");
      const response = await axios.delete(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.success) {
        setEntries(response.data.config.entries);
        setMessage("Entry deleted successfully.");
      } else {
        setMessage("Failed to delete entry.");
      }
    } catch (error) {
      console.error("Error deleting entry:", error);
      setMessage("Error deleting entry.");
    }
  };

  const handleSwapEntries = async (index1, index2) => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const apiUrl = new URL("/api/config/questions/swap", baseUrl).toString();

      const token = localStorage.getItem("admin-token");
      const response = await axios.put(apiUrl, { index1, index2 }, { headers: { Authorization: `Bearer ${token}` } });

      if (response.data.success) {
        setEntries(response.data.config.entries);
        setMessage("Ordem alterada com sucesso.");
      } else {
        setMessage("Falha ao alterar a ordem.");
      }
    } catch (error) {
      console.error("Error swapping entries:", error);
      setMessage("Falha ao alterar a ordem");
    }
  };

  const handleMoveUp = (index) => {
    if (index === 0) return;
    handleSwapEntries(index, index - 1);
  };

  const handleMoveDown = (index) => {
    if (index === entries.length - 1) return;
    handleSwapEntries(index, index + 1);
  };

  return (
    <div className="qa-management-popup">
      <div className="qa-management-popup__overlay" onClick={onClose} aria-label="Close popup overlay"></div>
      <div className="qa-management-popup__content" role="dialog" aria-labelledby="qa-popup-title">
        <h2 id="qa-popup-title">Gestão de FAQs</h2>

        <div className={`qa-management-popup__message ${message ? "visible" : ""}`} aria-live="polite">
          {message && <p>{message}</p>}
        </div>

        <div className="qa-management-popup__input-container">
          <textarea
            name="question"
            placeholder="Escreva a questão (140 characteres no máximo)"
            value={newEntry.question}
            onChange={handleInputChange}
            aria-label="Enter question"
            maxLength="140"
          />
          <textarea
            name="answer"
            placeholder="Escreva a resposta (140 characteres no máximo)"
            value={newEntry.answer}
            onChange={handleInputChange}
            aria-label="Enter answer"
            maxLength="140"
          />
          <button onClick={handleAddEntry} disabled={entries.length >= 10} aria-disabled={entries.length >= 10}>
            Adicionar
          </button>
        </div>

        <ul className="qa-management-popup__list">
          {(entries || []).map((entry, index) => (
            <li key={index} className="qa-management-popup__item">
              <div className="qa-management-popup__priority">
                <button onClick={() => handleMoveUp(index)} disabled={index === 0} aria-label="Move up" className="qa-management-popup__arrow">
                  <FiArrowUp />
                </button>
                <span className="qa-management-popup__priority-number">{index + 1}</span>
                <button
                  onClick={() => handleMoveDown(index)}
                  disabled={index === entries.length - 1}
                  aria-label="Move down"
                  className="qa-management-popup__arrow"
                >
                  <FiArrowDown />
                </button>
              </div>
              <span className="qa-management-popup__item-text">
                <strong>Q:</strong> {entry.question} <br />
                <strong>A:</strong> {entry.answer}
              </span>
              <button
                onClick={() => handleDeleteEntry(index)}
                className="qa-management-popup__delete"
                title="Delete entry"
                aria-label={`Delete question ${index + 1}`}
              >
                <FiTrash2 />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default QuestionAnswerManagementPopup;
