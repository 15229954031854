import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { io } from "socket.io-client";

import { useChatbotUserMessagesContext } from "./ChatbotUserMessagesContext";

const ChatbotSocketContext = createContext();
const api_url = document.getElementById("ChatBotA360")?.getAttribute("data-api_url") || process.env.REACT_APP_API_URL;
export const useChatbotSocket = () => {
  return useContext(ChatbotSocketContext);
};

export const ChatbotSocketProvider = ({ children }) => {
  const { token } = useChatbotUserMessagesContext();
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    if (socket) {
      socket.on("error", (data) => {
        console.error("Socket error:", data.message);
        setSocket(null);
      });

      // Cleanup on component unmount
      return () => {
        socket.off("error");
      };
    }
  }, [socket]);
  // Update the token whenever it changes in localStorage

  // Initialize and connect the socket
  const initializeSocket = useCallback(() => {
    if (!token) {
      console.error("Socket initialization failed: No token provided.");
      setSocket(null);
      return null;
    }

    const newSocket = io(api_url, {
      auth: { token },
    });

    newSocket.on("connect", () => {
      console.log("Socket connected:", newSocket.id);
      setSocket(newSocket);
      setIsConnected(true);
    });

    newSocket.on("disconnect", () => {
      console.log("Socket disconnected");
      setSocket(null);
      setIsConnected(false);
    });

    newSocket.on("connect_error", (err) => {
      console.error("Socket connection error:", err.message);
      setIsConnected(false);
    });

    return newSocket;
  }, [token]);

  useEffect(() => {
    if (!token) {
      console.warn("No valid chatbot token found for socket initialization.");
      return;
    }

    // Initialize socket connection
    let newSocket = initializeSocket();

    // Cleanup on component unmount
    return () => {
      if (newSocket) {
        newSocket.disconnect();
        setSocket(null);
      }
    };
  }, [token, initializeSocket]);

  return <ChatbotSocketContext.Provider value={socket}>{children}</ChatbotSocketContext.Provider>;
};
