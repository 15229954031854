//******************************************************************************************
// Logout Function: Clears the admin's session by removing authentication tokens and user data
// from local storage, then redirects the user to the login page.
//******************************************************************************************

export const logout = () => {
  // Remove the token from local storage
  localStorage.removeItem("admin-token");
  localStorage.removeItem("chat-user");

  window.location.href = "/login";
};

export default logout;
