//******************************************************************************************
// MessageInput Component: Provides an input field for typing messages and a button to send
// messages. Validates and sends user messages to the backend API and notifies the parent
// component on successful submission.
//******************************************************************************************

import React, { useState, useRef, useEffect } from "react";
import "./MessageInput.scss";

import { useChatbotSocket } from "../../../context/ChatbotSocketContext";
import { useChatbotUserMessagesContext } from "../../../context/ChatbotUserMessagesContext";

const MessageInput = ({ onMessageSent, disabled, api_url }) => {
  const [message, setMessage] = useState("");
  const { token, conversationId, setConversationId, botActive, setIsWaitingForHuman } = useChatbotUserMessagesContext();

  const socket = useChatbotSocket();
  const typingTimeoutRef = useRef(null);
  const lastTypingEmitRef = useRef(0);

  useEffect(() => {
    if (!socket || !conversationId) return;

    // Clear any leftover typing state when conversationId changes
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
      socket.emit("stop typing", { conversationId });
    }

    return () => {
      // Cleanup typing state on unmount
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
        socket.emit("stop typing", { conversationId });
      }
    };
  }, [socket, conversationId]);
  // Handle input change and typing detection
  const handleInputChange = (e) => {
    setMessage(e.target.value);

    if (socket && conversationId) {
      const now = Date.now();

      // Only emit 'typing' event if 3 seconds have passed since the last emit
      if (now - lastTypingEmitRef.current > 3000) {
        socket.emit("typing", { conversationId });
        lastTypingEmitRef.current = now;
      }

      // Clear the previous timeout
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }

      // Set a timeout to emit 'stop typing' after 7 seconds of inactivity
      typingTimeoutRef.current = setTimeout(() => {
        socket.emit("stop typing", { conversationId });
      }, 7000);
    }
  };

  // Handle sending the message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!botActive) {
      setIsWaitingForHuman(true);
    }
    if (!message.trim() || disabled) return;

    // Emit 'stop typing' when message is sent
    if (socket && conversationId) {
      socket.emit("stop typing", { conversationId });
    }

    setMessage("");
    try {
      if (!token) throw new Error("No token found");

      const endpoint = "/api/chatbot/send";
      const apiUrl = new URL(endpoint, api_url).toString();

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ message }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Error sending message");
      }

      if (onMessageSent) {
        onMessageSent();
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <form className="message-input" onSubmit={handleSendMessage}>
      <input type="text" placeholder="Escreva uma mensagem..." value={message} onChange={handleInputChange} disabled={disabled} />
      <button type="submit" disabled={!message.trim() || disabled}>
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path>
        </svg>
      </button>
    </form>
  );
};

export default MessageInput;
