import React from "react";
import { BiLogOut } from "react-icons/bi";
import logout from "../../../utils/logout.js";
import "./AdminLogoutButton.scss";
import SettingsIcon from "../ControlIcons/SettingsIcon.jsx";
import UserIcon from "../ControlIcons/UserIcon.jsx";
import QuestionAnswerIcon from "../ControlIcons/QuestionAnswerIcon.jsx";
import LogsIcon from "../ControlIcons/LogsIcon.jsx";
import KeyIcon from "../ControlIcons/KeyIcon.jsx";
import { useAuthContext } from "../../../context/AuthContext";

const LogoutButton = ({ onSettingsClick, onUserManagementClick, onQAManagementClick, settingsVisible, FAQVisible, onLogsClick, onChangePasswordClick }) => {
  const { authUser } = useAuthContext();

  // Function to process username
  const getDisplayName = (username) => {
    if (!username) return "Admin";
    const firstPart = username.split(".")[0]; // Split at the first `.`
    return firstPart.length > 15 ? firstPart.slice(0, 15) : firstPart; // Truncate if longer than 15
  };

  return (
    <div className="logout-container">
      <div className="logout-button">
        <div className="logout-button__section logout-button__left">
          <button className="user-info" onClick={onChangePasswordClick} title={`Change password for ${authUser?.username || "Admin"}`}>
            <KeyIcon />
            <span className={`logout-admin-username ${authUser?.isSuperAdmin ? "super-admin" : "regular-user"}`}>{getDisplayName(authUser?.username)}</span>
          </button>
          <div>
            <div className="logout-action" onClick={logout}>
              <BiLogOut className="logout-button__icon" />
              <p className="logout-button__text">Sair</p>
            </div>
          </div>
        </div>
        {(FAQVisible || settingsVisible) && <div className="logout-button__section logout-button__middle"></div>}
        {(FAQVisible || settingsVisible) && (
          <div className="logout-button__section logout-button__right">
            {settingsVisible && (
              <>
                <SettingsIcon onClick={onSettingsClick} />
                <UserIcon onClick={onUserManagementClick} />
                <LogsIcon onClick={onLogsClick} />
              </>
            )}
            <QuestionAnswerIcon onClick={onQAManagementClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LogoutButton;
