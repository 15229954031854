import React, { useState } from "react";
import axios from "axios";
import "./ChangePasswordPopup.scss";
import { useAuthContext } from "../../../context/AuthContext";

const ChangePasswordPopup = ({ onClose }) => {
  const { authUser } = useAuthContext();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setMessage("Por favor, preencha todos os campos.");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setMessage("As novas passwords não coincidem.");
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("admin-token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/change-password`,
        { currentPassword, newPassword },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.success) {
        setMessage("Password alterada com sucesso.");
        // Limpar os campos após sucesso
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      } else {
        setMessage(response.data.message || "Falha ao alterar a password.");
      }
    } catch (error) {
      console.error("Erro ao alterar a password:", error);
      setMessage(error.response?.data?.message || "Erro ao alterar a password.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="change-password-popup">
      <div className="change-password-popup__overlay" onClick={onClose}></div>
      <div className="change-password-popup__content">
        <h2>Alterar Password</h2>
        <p className="change-password-popup__logged-in">Utilizador: {authUser.username}</p>
        <div className="change-password-popup__message-container">{message && <p className="change-password-popup__message">{message}</p>}</div>
        <div className="change-password-popup__form">
          <div className="change-password-popup__form-group">
            <label htmlFor="currentPassword">Password Atual:</label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              placeholder="Insira a password atual"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              disabled={loading}
            />
          </div>
          <div className="change-password-popup__form-group">
            <label htmlFor="newPassword">Nova Password:</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              placeholder="Insira a nova password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              disabled={loading}
            />
          </div>
          <div className="change-password-popup__form-group">
            <label htmlFor="confirmNewPassword">Repetir Nova Password:</label>
            <input
              type="password"
              id="confirmNewPassword"
              name="confirmNewPassword"
              placeholder="Repita a nova password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              disabled={loading}
            />
          </div>
          <div className="change-password-popup__button-group">
            <button onClick={handleChangePassword} disabled={loading} className="change-password-popup__action-button">
              Alterar Password
            </button>
            <button onClick={onClose} disabled={loading} className="change-password-popup__action-button change-password-popup__cancel-button">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPopup;
