//******************************************************************************************
// AdminDashboard Component: Main dashboard for admin users to manage conversations,
// settings, user roles, and Q&A entries. Provides UI for accessing various management
// tools and for viewing/administering conversations with users.
//******************************************************************************************

import React, { useState } from "react";
import AdminConversations from "./Conversation/AdminConversations";
import AdminMessageContainer from "./Message/AdminMessageContainer";
import LogoutButton from "./Message/AdminLogoutButton";
import "./AdminDashboard.scss";
import { useAdminMessagesContext, AdminMessagesContextProvider } from "../../context/AdminMessagesContext";
import { DASH_TEXT_CONSTANTS } from "../../utils/constants";
import { useAuthContext } from "../../context/AuthContext";
import SettingsPopup from "./ControlPopups/SettingsPopup";
import UserManagementPopup from "./ControlPopups/UserManagementPopup";
import QuestionAnswerManagementPopup from "./ControlPopups/QuestionAnswerManagementPopup";
import LogsPopup from "./ControlPopups/LogsPopup";
import ChangePasswordPopup from "./ControlPopups/ChangePasswordPopup";
import moment from "moment";
import { AdminSocketProvider } from "../../context/AdminSocketContext";

const AdminDashboard = () => {
  return (
    <AdminSocketProvider>
      <AdminMessagesContextProvider>
        <AdminDashboardContent />
      </AdminMessagesContextProvider>
    </AdminSocketProvider>
  );
};

const AdminDashboardContent = () => {
  const { setStartDate, setEndDate, fetchConversations, selectedConversation, setSelectedConversation, conversations, setConversations } =
    useAdminMessagesContext();
  const { authUser } = useAuthContext();
  const [showSettings, setShowSettings] = useState(false);
  const [showUserManagement, setShowUserManagement] = useState(false);
  const [showQAManagement, setShowQAManagement] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [localStartDate, setLocalStartDate] = useState(moment().subtract(6, "days").format("YYYY-MM-DD"));
  const [localEndDate, setLocalEndDate] = useState(moment().format("YYYY-MM-DD"));

  const handleSettingsClick = () => setShowSettings(true);
  const handleUserManagementClick = () => setShowUserManagement(true);
  const handleQAManagementClick = () => setShowQAManagement(true);
  const handleLogsClick = () => setShowLogs(true);
  const handleChangePasswordClick = () => setShowChangePassword(true);

  // Handlers for date input changes
  const handleLocalStartDateChange = (e) => {
    setLocalStartDate(e.target.value);
  };

  const handleLocalEndDateChange = (e) => {
    setLocalEndDate(e.target.value);
  };

  const handleDateApply = () => {
    setStartDate(localStartDate);
    setEndDate(localEndDate);
    fetchConversations();
  };

  const handleSelectConversation = (conversation) => {
    setSelectedConversation(conversation);

    // Mark the conversation as read locally
    setConversations((prevConversations) => prevConversations.map((conv) => (conv._id === conversation._id ? { ...conv, adminSeen: true } : conv)));
  };

  return (
    <div className="admin-sidebar">
      <div className="admin-sidebar__conversations">
        {" "}
        <div className="logos">
          <img src="/A360.svg" alt="A360 Logo" className="a360_logo" />
          <img src="/mun_trofa.svg" alt="Mun Trofa Logo" className="municipal_logo" />
        </div>
        <div className="admin-sidebar__conversations-list">
          <AdminConversations onSelectConversation={handleSelectConversation} selectedConversationId={selectedConversation?._id} />
        </div>
        {authUser?.isSuperAdmin && (
          <div className="admin-sidebar__date-filters">
            <label>
              <span>Data Início:</span>
              <span>Data Fim:</span>
            </label>
            <label>
              <input type="date" value={localStartDate} onChange={handleLocalStartDateChange} />
              <input type="date" value={localEndDate} onChange={handleLocalEndDateChange} />
            </label>
            <button onClick={handleDateApply}>Aplicar</button>
          </div>
        )}
        <div className="admin-sidebar__logout">
          <LogoutButton
            onSettingsClick={handleSettingsClick}
            onUserManagementClick={handleUserManagementClick}
            onQAManagementClick={handleQAManagementClick}
            onLogsClick={handleLogsClick}
            onChangePasswordClick={handleChangePasswordClick}
            settingsVisible={authUser?.isSuperAdmin}
            FAQVisible={authUser?.canManageFAQ}
            authUser={authUser}
          />
        </div>
      </div>

      <div className={`admin-sidebar__message-container ${!selectedConversation ? "centered" : ""}`}>
        {selectedConversation ? (
          <AdminMessageContainer conversationId={selectedConversation._id} />
        ) : (
          <div className="admin-sidebar__message-box">
            <p>{conversations.length === 0 ? DASH_TEXT_CONSTANTS.NO_CONVERSATION_PROMPT : DASH_TEXT_CONSTANTS.SELECT_CONVERSATION_PROMPT}</p>
          </div>
        )}
      </div>
      {showSettings && <SettingsPopup onClose={() => setShowSettings(false)} />}
      {showUserManagement && <UserManagementPopup onClose={() => setShowUserManagement(false)} />}
      {showLogs && <LogsPopup onClose={() => setShowLogs(false)} />}
      {showQAManagement && <QuestionAnswerManagementPopup onClose={() => setShowQAManagement(false)} />}
      {showChangePassword && <ChangePasswordPopup onClose={() => setShowChangePassword(false)} />}
    </div>
  );
};

export default AdminDashboard;
