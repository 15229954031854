//******************************************************************************************
// ConsentDisclaimer Component: Displays a terms and privacy consent form with scrolling
// requirement and checkboxes for user consent. Only enables the accept button once the user
// has scrolled to the end of the terms and checked both boxes. Initiates chatbot session
// creation upon acceptance.
//******************************************************************************************

import React, { useState, useRef, useEffect } from "react";
import "./ConsentDisclaimer.scss";

const ConsentDisclaimer = ({ onConsentAccepted, disclaimerVersion }) => {
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [loading, setLoading] = useState(false);
  const textRef = useRef(null);

  // Handle user consent acceptance and initiate chatbot session creation
  const handleAccept = () => {
    if (!checked1 || !checked2) return;
    localStorage.setItem("disclaimerVersion", disclaimerVersion); // Save the accepted version
    onConsentAccepted();
  };

  return (
    <div className="consent-disclaimer">
      <h2>Termos de Uso</h2>
      <div className="consent-disclaimer__text" ref={textRef}>
        <h3>1. Tecnologia utilizada (Inteligência Artificial)</h3>
        <p>
          O chatbot implementado neste site utiliza inteligência artificial para responder às suas perguntas. No entanto, existem algumas limitações das quais
          os utilizadores devem estar cientes:
        </p>
        <ul>
          <li>
            Embora este chatbot tenha sido projetado para dar respostas precisas e úteis, pode, ocasionalmente, fornecer informações incorretas ou imprecisas.
            Assim sendo, este chatbot não substitui o atendimento humano, que deverá ser procurado em casos complexos ou críticos.
          </li>
          <li>
            O chatbot poderá ter dificuldades em interpretar corretamente mensagens que contenham gírias, erros de digitação, ou expressões ambíguas. A clareza
            na formulação das perguntas ajudará a melhorar a precisão das respostas.
          </li>
          <li>
            O chatbot poderá não ter acesso ao histórico completo das suas interações anteriores, o que pode limitar sua capacidade de fornecer respostas
            contextualmente precisas em conversas contínuas.
          </li>
        </ul>

        <h3>2. Dados</h3>
        <p>
          Durante a utilização do chatbot, poderemos guardar informações trocadas com o chatbot (data e hora das interações, duração das sessões, e o conteúdo
          das mensagens trocadas).
        </p>
        <p>As informações recolhidas são utilizadas para:</p>
        <ul>
          <li>Análise e melhoria do serviço: Avaliar a eficácia do chatbot, identificar tendências de uso e implementar melhorias.</li>
          <li>Fornecer ao atendimento humano o histórico de conversação prévio com o chatbot, de modo a responder mais agilmente às questões.</li>
        </ul>
        <p>
          A informação guardada pelo chatbot é armazenada em servidores seguros e apenas pelo período necessário ao cumprimento das finalidades aqui descritas.
        </p>

        <h3>3. Informações pessoais</h3>
        <p>
          Não indique dados pessoais através deste chatbot. Se necessitar de ajuda relativamente a questões que envolvam dados pessoais, por favor, faça-o
          através do atendimento humano.
        </p>

        <h3>4. Alterações nos Termos e Condições</h3>
        <p>
          Poderemos atualizar estes Termos e Condições. As alterações serão publicadas nesta área, com a data de revisão atualizada. Recomendamos que reveja
          este conteúdo sempre que utilizar o chatbot.
        </p>

        <p>
          Para mais detalhes sobre como tratamos e protegemos os seus dados, consulte a nossa{" "}
          <a href="https://mun-trofa.pt/menu/422/privacidade" target="_blank">
            Política de Privacidade
          </a>
          .
        </p>
        <p>
          <strong>Data de atualização: 03/12/2024</strong>
        </p>
      </div>

      <div className="consent-disclaimer__checkbox">
        <input type="checkbox" checked={checked1} onChange={() => setChecked1(!checked1)} />
        <label>Aceito que os dados fornecidos sejam utilizados para fins de treino.</label>
      </div>
      <div className="consent-disclaimer__checkbox">
        <input type="checkbox" checked={checked2} onChange={() => setChecked2(!checked2)} />
        <label>
          Concordo com a{" "}
          <a href="https://mun-trofa.pt/menu/422/privacidade" target="_blank">
            política de privacidade
          </a>
          .
        </label>
      </div>
      <button className="consent-disclaimer__accept-button" disabled={!checked1 || !checked2 || loading} onClick={handleAccept}>
        {loading ? "Carregando..." : "Aceitar e Continuar"}
      </button>
    </div>
  );
};

export default ConsentDisclaimer;
