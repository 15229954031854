//******************************************************************************************
// MessageContainer Component: Main container for chatbot conversation, handling messages,
// user input, and socket integration for real-time chat updates. Includes consent management
// and controls for conversation status, such as displaying initial questions or closing the chat.
//******************************************************************************************

import React, { useState, useEffect, useRef, useCallback } from "react";
import Messages from "./Messages";
import MessageInput from "./MessageInput";
import "./MessageContainer.scss";
import { useChatbotSocket } from "../../../context/ChatbotSocketContext";
import { useChatbotUserMessagesContext } from "../../../context/ChatbotUserMessagesContext";
import ConsentDisclaimer from "../../disclaimer/ConsentDisclaimer";

const MessageContainer = ({ setRefreshConversationFn }) => {
  const DISCLAIMER_VERSION = "1.00";
  const storedVersion = localStorage.getItem("disclaimerVersion");

  const [loading, setLoading] = useState(false);
  const [allQuestions, setAllQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [showQuestions, setShowQuestions] = useState(false);
  const messagesEndRef = useRef(null);
  const socket = useChatbotSocket();

  const {
    messages,
    setMessages,
    isClosed,
    setIsClosed,
    isOverridden,
    conversationId,
    setConversationId,
    token,
    setToken,
    setIsOverridden,
    botActive,
    setBotActive,
    isWaitingForHuman,
    setIsWaitingForHuman,
  } = useChatbotUserMessagesContext();

  const api_url = document.getElementById("ChatBotA360")?.getAttribute("data-api_url") || process.env.REACT_APP_API_URL;

  const [isAdminTyping, setIsAdminTyping] = useState(false);
  const animationIntervalRef = useRef(null);
  const [typingStarted, setTypingStarted] = useState(false);
  const [typingIndicator, setTypingIndicator] = useState("...");
  const typingTimeoutRef = useRef(null);
  const [isConsentGiven, setIsConsentGiven] = useState(storedVersion === DISCLAIMER_VERSION && !!token);

  useEffect(() => {
    if (!socket || !conversationId) return;

    const handleConversationOverridden = (data) => {
      setIsWaitingForHuman(false);
      setIsOverridden(true);
    };

    const handleConversationClosed = (data) => {
      setIsClosed(true);
    };

    socket.on("conversationOverridden", handleConversationOverridden);
    socket.on("conversationClosed", handleConversationClosed);

    return () => {
      socket.off("conversationOverridden", handleConversationOverridden);
      socket.off("conversationClosed", handleConversationClosed);
    };
  }, [socket, conversationId]);

  // Handle consent acceptance and create chatbot session
  const handleConsentAccepted = async () => {
    await createNewConversation();
  };

  // Trigger typing started and stopped states
  const handleTypingStarted = () => {
    if (!typingStarted) {
      setTypingStarted(true);
      socket.emit("typing", { conversationId });
    }
  };

  const handleTypingStopped = () => {
    setTypingStarted(false);
    socket.emit("stop typing", { conversationId });
  };

  // Effect to listen for "typing" and "stop typing" events from the socket
  useEffect(() => {
    if (!socket || !conversationId) return;

    const handleAdminTyping = (data) => {
      if (data.conversationId === conversationId) {
        setIsAdminTyping(true);

        // Clear existing timeout
        if (typingTimeoutRef.current) {
          clearTimeout(typingTimeoutRef.current);
        }

        // Set a timeout to stop typing indicator after 5 seconds
        typingTimeoutRef.current = setTimeout(() => {
          setIsAdminTyping(false);
        }, 5000);
      }
    };

    const handleAdminStopTyping = (data) => {
      if (data.conversationId === conversationId) {
        setIsAdminTyping(false);

        // Clear the timeout since we received a stop typing event
        if (typingTimeoutRef.current) {
          clearTimeout(typingTimeoutRef.current);
        }
      }
    };

    socket.on("typing", handleAdminTyping);
    socket.on("stop typing", handleAdminStopTyping);

    return () => {
      socket.off("typing", handleAdminTyping);
      socket.off("stop typing", handleAdminStopTyping);

      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [socket, conversationId]);
  //Update typing indicator when admin is typing
  useEffect(() => {
    if (isAdminTyping) {
      animationIntervalRef.current = setInterval(() => {
        setTypingIndicator((prev) => (prev === "..." ? "." : prev === ".." ? "..." : ".."));
      }, 500);
    } else {
      setTypingIndicator("...");
      if (animationIntervalRef.current) {
        clearInterval(animationIntervalRef.current);
      }
    }

    return () => {
      if (animationIntervalRef.current) {
        clearInterval(animationIntervalRef.current);
      }
    };
  }, [isAdminTyping]);

  // Fetch questions that are still unanswered
  const fetchQuestions = useCallback(async () => {
    try {
      const response = await fetch(`${api_url}/api/chatbot/init-questions`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("data", data);
      if (data.success) {
        // Set all remaining questions
        setAllQuestions(data.questions);
        // Set currently displayed questions (up to 3)
        setQuestions(data.questions.slice(0, 3));
      } else {
        console.error("Failed to initialize questions:", data.message);
      }
    } catch (error) {
      console.error("Error initializing questions:", error);
    }
  }, [token]);

  // Show the answer for a selected question
  const showAnswerForQuestion = async (questionId, questionText) => {
    try {
      const response = await fetch(`${api_url}/api/chatbot/send-question`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ questionId, questionText }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Error sending question as message");
      }

      // Remove answered question from allQuestions
      setAllQuestions((prevAllQuestions) => {
        const updatedAllQuestions = prevAllQuestions.filter((q) => q._id !== questionId);
        // Update displayed questions
        setQuestions(updatedAllQuestions.slice(0, 3));
        return updatedAllQuestions;
      });

      // Hide questions after one is selected
      setShowQuestions(false);
    } catch (error) {
      console.error("Error sending question as message:", error);
    }
  };

  // Function to create a new conversation and set token and conversation ID
  const createNewConversation = useCallback(async () => {
    setLoading(true);
    try {
      const endpoint = "/api/chatbot/create";
      const apiUrl = new URL(endpoint, api_url).toString();

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();

      if (data.success) {
        const newToken = data.token;
        const newConversationId = data.user.botpressConversationId;
        const botStatus = data.botStatus;
        setIsConsentGiven(true);
        setToken(newToken);
        setConversationId(newConversationId);
        setBotActive(botStatus);
        setIsWaitingForHuman(data.isWaitingForHuman);
        await fetchQuestions();
      } else {
        console.error("Failed to create chatbot user:", data.message);
      }
    } catch (error) {
      console.error("Error creating chatbot user:", error.message);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setIsConsentGiven, setToken, setConversationId, fetchQuestions]);

  // Function to refresh conversation
  const refreshConversation = useCallback(async () => {
    if (socket && conversationId) {
      socket.emit("stop typing", { conversationId }); // Notify admin to stop typing
    }
    try {
      setLoading(true);

      // Call the backend API to reset the conversation
      const response = await fetch(`${api_url}/api/chatbot/resetConversation`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ conversationId }),
      });

      const data = await response.json();

      // Clear messages and state
      setToken(null);
      setConversationId(null);
      setIsClosed(false);
      setMessages([]);
      setIsAdminTyping(false);
      setBotActive(false);
      setIsWaitingForHuman(false);
      // Start a new conversation
      await createNewConversation();
    } catch (error) {
      console.error("Error resetting conversation:", error);
    } finally {
      setLoading(false);
    }
  }, [socket, conversationId, token, setToken, setConversationId, setIsClosed, setMessages, setIsAdminTyping, createNewConversation]);
  useEffect(() => {
    if (setRefreshConversationFn) {
      setRefreshConversationFn(() => refreshConversation);
    }
  }, [setRefreshConversationFn, refreshConversation]);
  // Clear timeout when conversation or connection refreshes
  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [conversationId, socket]);

  // Fetch messages for the current conversation
  const fetchMessages = async () => {
    try {
      if (!token || !conversationId) throw new Error("Missing token or conversation ID");
      const endpoint = `/api/chatbot/messages`;
      const apiUrl = new URL(endpoint, api_url).toString();
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          console.warn("Conversation not found. Starting a new conversation.");
          refreshConversation();
          return;
        }

        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      setMessages(data.messages);

      const botStatus = data.botStatus;
      setBotActive(botStatus);
      setIsWaitingForHuman(data.isWaitingForHuman);
      if (data.Closed) {
        setIsClosed(true);
      }
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

      if (data.isOverridden !== undefined) {
        setIsOverridden(data.isOverridden);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  // Function to determine if the last message was from the bot, used to show questions
  const isLastMessageFromBot = () => {
    if (messages.length === 0) return false;
    const lastMessage = messages[messages.length - 1];

    return (lastMessage.senderModel === "Bot" || lastMessage.senderModel === "SYSTEM") && lastMessage.isWarning === false && !isClosed;
  };

  // Load messages and questions when conversation and consent states are set
  useEffect(() => {
    if (conversationId && token && isConsentGiven) {
      fetchMessages();
      fetchQuestions();
    }
  }, [conversationId, token, isConsentGiven]);

  // Show questions after a delay when the last message is from the bot
  useEffect(() => {
    if (isLastMessageFromBot()) {
      const timer = setTimeout(() => {
        if (questions.length === 0 || questions.length === null) {
          // Show only "Falar com um agente humano" if no questions are available
          setShowQuestions(true);
        } else {
          setShowQuestions(true);
        }
        // Scroll to bottom when showing questions
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }, 3000);

      return () => clearTimeout(timer);
    } else {
      setShowQuestions(false);
    }
  }, [messages, questions]);

  // Request human agent when user clicks on the button
  const requestHumanAgent = async () => {
    try {
      const response = await fetch(`${api_url}/api/chatbot/request-human`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Error requesting human agent");
      }

      setShowQuestions(false);
    } catch (error) {
      console.error("Error requesting human agent:", error);
    }
  };

  // Listen for new messages from the socket and scroll to the latest message
  useEffect(() => {
    if (!socket || !conversationId) return;

    const handleAdminTyping = (data) => {
      if (data.conversationId === conversationId) {
        setIsAdminTyping(true);
      }
    };

    const handleAdminStopTyping = (data) => {
      if (data.conversationId === conversationId) {
        setIsAdminTyping(false);
      }
    };

    socket.on("typing", handleAdminTyping);
    socket.on("stop typing", handleAdminStopTyping);

    return () => {
      socket.off("typing", handleAdminTyping);
      socket.off("stop typing", handleAdminStopTyping);
    };
  }, [socket, conversationId]);

  // Listen for new messages from the socket and scroll to the latest message
  useEffect(() => {
    if (!socket || !conversationId) return;
    const handleNewMessage = (newMessage) => {
      setMessages((prevMessages) => {
        // Check for duplicate messages
        const messageExists = prevMessages.some((msg) => msg._id === newMessage._id);
        if (messageExists) {
          return prevMessages;
        } else {
          return [...prevMessages, newMessage];
        }
      });

      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    };
    socket.on("newMessage", handleNewMessage);
    socket.emit("joinConversation", conversationId);

    return () => {
      socket.off("newMessage", handleNewMessage);
      socket.emit("leaveConversation", conversationId);
    };
  }, [socket, conversationId]);
  return (
    <div className="message-container">
      {loading ? (
        <div className="message-container__loading">A carregar uma nova conversa ...</div>
      ) : isConsentGiven ? (
        <>
          <div className="message-container__messages">
            {typingStarted && <div className="message-container__typing-indicator">{typingIndicator}</div>}
            <Messages messages={messages} />
            {showQuestions && !isClosed && !isOverridden && (
              <>
                <div onClick={requestHumanAgent} className="message-container__question">
                  Clique aqui para falar com um agente humano
                </div>
                {questions.length > 0 &&
                  questions.map((question) => (
                    <div key={question._id} onClick={() => showAnswerForQuestion(question._id, question.question)} className="message-container__question">
                      {question.question}
                    </div>
                  ))}
              </>
            )}
            <div ref={messagesEndRef} />
          </div>
          {(isAdminTyping || typingStarted) && (
            <div className="message-container__typing-indicator">
              O {isOverridden ? "agente" : "bot"} está a escrever{typingIndicator}
            </div>
          )}
          <div className="message-container__input">
            {isClosed ? (
              <div className="message-container__closed-message">
                <p>Esta conversa encontra-se fechada</p>
              </div>
            ) : (
              <MessageInput token={token} onTypingStart={handleTypingStarted} onTypingStop={handleTypingStopped} api_url={api_url} />
            )}
          </div>
        </>
      ) : (
        <ConsentDisclaimer onConsentAccepted={handleConsentAccepted} disclaimerVersion={DISCLAIMER_VERSION} />
      )}
    </div>
  );
};
export default MessageContainer;
