import React from "react";
import { AiOutlineFileText } from "react-icons/ai";
import "./LogsIcon.scss";

const LogsIcon = ({ onClick }) => {
  return (
    <button className="logs-icon" onClick={onClick} title="View Logs">
      <AiOutlineFileText size={24} />
    </button>
  );
};

export default LogsIcon;
