//******************************************************************************************/
// Component that renders an individual message in the admin chat.
// It formats the message bubble based on whether it's sent by an admin, bot, or citizen.
//******************************************************************************************/

import React from "react";
import "./AdminMessage.scss";
import { SPEAKER_NAME_CONSTANTS } from "../../../utils/constants";

const AdminMessage = ({ message }) => {
  const isAdminSender = message.senderModel === "Admin";
  const isWarning = message.isWarning;
  let senderName;
  let messageClass;

  // Determine who sent the message and assign appropriate class and sender name
  if (isAdminSender) {
    senderName = message.senderId?.username || SPEAKER_NAME_CONSTANTS.ADMIN; // Default to 'Admin'
    messageClass = "admin"; // Apply 'admin' styles to the message
  } else if (message.senderModel === "Bot" || message.senderModel === "SYSTEM") {
    senderName = SPEAKER_NAME_CONSTANTS.BOT; // Default to 'Bot' if the sender is a bot
    messageClass = "bot"; // Apply 'bot' styles to the message
  } else {
    senderName = SPEAKER_NAME_CONSTANTS.CITIZEN; // Default to 'Cidadão' for chatbot user
    messageClass = "user"; // Apply 'user' styles to the message
  }

  // If the message is a warning, override the class to display warning styles
  if (isWarning) {
    messageClass = "warning";
  }

  // Function to process message text for bold and links
  const renderMessageText = (text) => {
    const elements = [];
    let lastIndex = 0;

    // Combined regex to match:
    // 1. Bold text: **text** or __text__
    // 2. Markdown links: [text](url)
    // 3. Plain URLs: http:// or https:// followed by non-space characters
    const regex = /(\*\*|__)(.*?)\1|\[([^\]]+)\]\((https?:\/\/[^\s]+)\)|(https?:\/\/[^\s]+)/g;

    let match;
    while ((match = regex.exec(text)) !== null) {
      const index = match.index;

      // Add text before the current match
      if (index > lastIndex) {
        elements.push(text.slice(lastIndex, index));
      }

      if (match[1] && match[2]) {
        // **Bold Text** or __Bold Text__
        elements.push(<strong key={`bold-${index}`}>{match[2]}</strong>);
      } else if (match[3] && match[4]) {
        // [Text](URL) -> Display URL as plain text
        const url = match[4];
        elements.push(
          <span key={`markdown-link-${index}`} className="admin-message__url">
            {url}
          </span>
        );
      } else if (match[5]) {
        // Plain URL -> Display as plain text
        const url = match[5];
        elements.push(
          <span key={`plain-link-${index}`} className="admin-message__url">
            {url}
          </span>
        );
      }

      lastIndex = regex.lastIndex;
    }

    // Add any remaining text after the last match
    if (lastIndex < text.length) {
      elements.push(text.slice(lastIndex));
    }

    return elements;
  };

  return (
    <div className={`admin-message admin-message--${messageClass}`}>
      <div className="admin-message__bubble">
        {!isWarning && <p className="admin-message__sender">{senderName}</p>}
        <p className="admin-message__text">{renderMessageText(message.message)}</p>
        {!isWarning && <p className="admin-message__time">{new Date(message.createdAt).toLocaleString()}</p>}
      </div>
    </div>
  );
};

export default AdminMessage;
