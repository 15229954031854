//******************************************************************************************
// AuthContextProvider: Manages authentication state across the app, handling admin data and
// permissions. Includes functionality to clear state on token expiration and redirect users
// to the login page. Exposes user state, a function to update it, and helper functions.
//******************************************************************************************

import React, { createContext, useContext, useState } from "react";

export const AuthContext = createContext();

// Custom hook to access the AuthContext in components
export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const [authUser, setAuthUserState] = useState(JSON.parse(localStorage.getItem("chat-user")) || null);
  const [token, setToken] = useState(localStorage.getItem("admin-token") || null);

  const handleExpiredToken = () => {
    const isInCMS = window.location.pathname.includes("/cms");

    if (isInCMS) {
      // Clear user data from localStorage and state
      localStorage.removeItem("chat-user");
      localStorage.removeItem("admin-token");
      setAuthUserState(null);
      setToken(null);

      // Redirect to login page only if in /cms
      window.location.href = "/login";
    }
  };

  // Method to update both authUser and token
  const updateAuth = (user, newToken) => {
    setAuthUserState(user);
    setToken(newToken);
    localStorage.setItem("chat-user", JSON.stringify(user));
    localStorage.setItem("admin-token", newToken);
  };

  // Method to clear auth (e.g., on logout)
  const clearAuth = () => {
    setAuthUserState(null);
    setToken(null);
    localStorage.removeItem("chat-user");
    localStorage.removeItem("admin-token");
  };

  // Check if the current user is a super admin
  const isSuperAdmin = authUser?.isSuperAdmin || false;
  const canCloseConversations = authUser?.canCloseConversations || false;

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser: updateAuth,
        clearAuth,
        handleExpiredToken,
        isSuperAdmin,
        canCloseConversations,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
