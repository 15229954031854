//******************************************************************************************
// Messages Component: Displays a list of chat messages, automatically scrolling to the
// latest message when new messages arrive. Renders individual Message components with
// fallback support for missing message content.
//******************************************************************************************

import React, { useEffect, useRef } from "react";
import Message from "./Message";
import "./Messages.scss";

const Messages = ({ messages }) => {
  const messagesEndRef = useRef(null);

  // Scroll to the bottom of the messages when the messages array updates
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="messages">
      {messages && messages.length > 0 ? (
        messages.map((message) => (
          <Message
            key={message._id || message.id}
            text={message.message || ""}
            time={new Date(message.createdAt).toLocaleTimeString()}
            senderModel={message.senderModel}
            isWarning={message.isWarning}
          />
        ))
      ) : (
        <p>Sem Mensagens</p>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default Messages;
