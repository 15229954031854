import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useAuthContext } from "./AuthContext";

const AdminSocketContext = createContext();

export const useAdminSocket = () => {
  return useContext(AdminSocketContext);
};

export const AdminSocketProvider = ({ children }) => {
  const { token, handleExpiredToken } = useAuthContext();
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  // Initialize and connect the socket whenever token changes
  useEffect(() => {
    // If there's an existing socket, disconnect it before creating a new one
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }

    if (!token) {
      console.warn("No valid admin token found for socket initialization.");
      return;
    }

    console.log("Attempting to initialize socket connection...");

    const newSocket = io(process.env.REACT_APP_API_URL, {
      auth: { token },
    });

    setSocket(newSocket);

    // Event listeners
    newSocket.on("connect", () => {
      console.log("Admin socket connected:", newSocket.id);
      setIsConnected(true);
    });

    newSocket.on("disconnect", (reason) => {
      console.log("Admin socket disconnected. Reason:", reason);
      setIsConnected(false);
    });

    newSocket.on("connect_error", (error) => {
      console.error("Socket connection error:", error.message);

      // Handle authentication failure
      if (error.message === "Authentication failed: Invalid or expired token") {
        console.warn("Token expired or invalid. Handling token expiration...");
        handleExpiredToken();
      }
    });

    newSocket.on("error", (error) => {
      console.error("Socket error:", error.message);
    });

    // Cleanup on component unmount or when token changes
    return () => {
      newSocket.disconnect();
    };
  }, [token]);

  return <AdminSocketContext.Provider value={{ socket, isConnected }}>{children}</AdminSocketContext.Provider>;
};
