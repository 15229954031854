//******************************************************************************************
// Main component to handle message input and displaying messages for the currently
// selected conversation.
//******************************************************************************************

import React, { useState, useEffect, useRef } from "react";
import AdminMessages from "./AdminMessages";
import "./AdminMessageContainer.scss";
import { useAuthContext } from "../../../context/AuthContext";
import { FiSend, FiXCircle, FiAlertTriangle } from "react-icons/fi";
import { useAdminMessagesContext } from "../../../context/AdminMessagesContext";
import { useAdminSocket } from "../../../context/AdminSocketContext";
import { BUTTON_TEXT_CONSTANTS } from "../../../utils/constants";
import moment from "moment";
import LogsPopup from "../ControlPopups/LogsPopup";
import { AiOutlineFileText } from "react-icons/ai";
import AdminReviewPopup from "../ControlPopups/AdminReviewPopup";

const AdminMessageContainer = () => {
  const { authUser, handleExpiredToken } = useAuthContext();
  const { messages, selectedConversation, setSelectedConversation, BotRoomId } = useAdminMessagesContext();

  const { socket, isConnected } = useAdminSocket();

  const [showLogsPopup, setShowLogsPopup] = useState(false);
  const [showAdminReviewPopup, setShowAdminReviewPopup] = useState(false);

  const [message, setMessage] = useState("");
  const [isUserTyping, setIsUserTyping] = useState(false);
  const typingTimeoutRef = useRef(null);
  const [typingIndicator, setTypingIndicator] = useState("...");
  const [typingStarted, setTypingStarted] = useState(false);
  const animationIntervalRef = useRef(null);

  const prevConversationIdRef = useRef(null);

  // Effect to manage the animated typing indicator ("...")
  useEffect(() => {
    animationIntervalRef.current = setInterval(() => {
      setTypingIndicator((prev) => (prev === "..." ? "." : prev === ".." ? "..." : ".."));
    }, 500);

    return () => clearInterval(animationIntervalRef.current);
  }, []);

  // Effect to listen for "typing" and "stop typing" events from the socket
  useEffect(() => {
    if (selectedConversation?._id !== prevConversationIdRef.current) {
      setMessage("");
      prevConversationIdRef.current = selectedConversation?._id;
    }
    setIsUserTyping(false);
    setTypingStarted(false);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
  }, [selectedConversation]);

  // Handle input changes and emit typing events
  const handleInputChange = (e) => {
    setMessage(e.target.value);
    if (socket && BotRoomId) {
      if (!typingStarted) {
        socket.emit("typing", { conversationId: BotRoomId });
        setTypingStarted(true);
      }
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => {
        socket.emit("stop typing", { conversationId: BotRoomId });
        setTypingStarted(false);
      }, 2000);
    }
  };

  // Effect to set up socket event listeners for conversation-specific updates
  useEffect(() => {
    if (!socket || !isConnected || !BotRoomId) return;

    // Join the conversation room on the admin side
    socket.emit("joinConversation", BotRoomId);

    const handleUserTyping = (data) => {
      if (data.conversationId === BotRoomId) {
        setIsUserTyping(true);
        if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
        // Set a new timeout to hide the typing indicator after 3 seconds
        typingTimeoutRef.current = setTimeout(() => {
          setIsUserTyping(false);
        }, 3000);
      }
    };

    const handleUserStopTyping = (data) => {
      if (data.conversationId === BotRoomId) {
        setIsUserTyping(false);
        // Clear the timeout since we received a stop typing event
        if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
      }
    };

    socket.on("typing", handleUserTyping);
    socket.on("stop typing", handleUserStopTyping);

    return () => {
      socket.off("typing", handleUserTyping);
      socket.off("stop typing", handleUserStopTyping);

      // Clear the timeout when the component unmounts or conversation changes
      if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    };
  }, [socket, isConnected, BotRoomId]);

  const handleUnderReview = () => {
    setShowAdminReviewPopup(true);
  };

  // Function to handle sending a message as Admin
  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!message.trim()) return;

    try {
      const token = localStorage.getItem("admin-token");
      const baseUrl = process.env.REACT_APP_API_URL;
      const endpoint = `/api/admin/messages/${selectedConversation._id}/`;
      const apiUrl = new URL(endpoint, baseUrl).toString();
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ message }),
      });

      const data = await response.json();
      if (socket && selectedConversation) {
        socket.emit("stop typing", { conversationId: BotRoomId });
      }
      if (data.success) {
        setMessage("");
      } else {
        console.error("Failed to send message:", data.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // Function to handle closing a conversation
  const handleCloseConversation = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("admin-token");
      const baseUrl = process.env.REACT_APP_API_URL;
      const endpoint = `/api/admin/conversations/${selectedConversation._id}/close`;
      const apiUrl = new URL(endpoint, baseUrl).toString();
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ message }),
      });
      const data = await response.json();

      if (data.success) {
        setSelectedConversation((prev) => ({
          ...prev,
          closed: true,
          closedBy: authUser.username,
          closedAt: new Date().toISOString(),
        }));
      } else {
        console.error("Failed to close conversation:", data.message);
      }
    } catch (error) {
      console.error("Error closing conversation:", error);
    }
  };

  // Function to handle overriding a conversation as an Admin
  const handleOverride = async () => {
    try {
      const token = localStorage.getItem("admin-token");
      let endpoint;

      if (!socket) {
        console.error("Socket is not initialized. Please wait.");
        return;
      }

      if (!selectedConversation.isOverridden) {
        endpoint = `/api/admin/conversations/${selectedConversation._id}/override`;
      } else if (selectedConversation.isOverridden && !selectedConversation.overriddenByAdminId) {
        endpoint = `/api/admin/conversations/${selectedConversation._id}/assign`;
      }

      const apiUrl = new URL(endpoint, process.env.REACT_APP_API_URL).toString();

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        handleExpiredToken();
        return;
      }
      const data = await response.json();

      if (data.success) {
        setSelectedConversation((prev) => ({
          ...prev,
          isOverridden: true,
          overriddenByAdminId: authUser._id,
        }));
      } else {
        console.error("Failed to override conversation:", data.message);
      }
    } catch (error) {
      console.error("Error overriding conversation:", error);
    }
  };

  if (!selectedConversation) {
    return <p>Loading conversation...</p>;
  }

  // Determine the appropriate input section based on the state of the conversation
  let inputSection;

  // If the conversation is closed, show the closed message
  if (selectedConversation.closed) {
    const closedByAdminName = selectedConversation.closedBy || "Desconhecido";
    const closedDate = moment(selectedConversation.closedAt).format("MMMM Do YYYY, h:mm:ss a");
    inputSection = (
      <div className="admin-message-container__closed">
        <p>
          Fechado por {closedByAdminName} no dia {closedDate}
        </p>
      </div>
    );
  }
  // If the conversation is waiting for an admin to take over
  else if (selectedConversation.isOverridden && selectedConversation.overriddenByAdminId === null) {
    inputSection = (
      <button onClick={handleOverride} className="admin-message-container__override">
        {BUTTON_TEXT_CONSTANTS.ASSUME_CONVERSATION}
      </button>
    );
  }
  // If the conversation is overridden by another admin
  else if (selectedConversation.isOverridden && selectedConversation.overriddenByAdminId !== authUser._id) {
    const overriddenByAdmin = selectedConversation.participants
      ? selectedConversation.participants.find((p) => p.participantId._id === selectedConversation.overriddenByAdminId)
      : null;

    inputSection = (
      <button className="admin-message-container__override admin-message-container__override--disabled" disabled>
        {BUTTON_TEXT_CONSTANTS.CONVERSATION_ASSUMED_BY + " "}
        {overriddenByAdmin?.participantId?.username || "Unknown"}
      </button>
    );
  }
  // If the conversation is overridden by the current admin
  else if (selectedConversation.isOverridden && selectedConversation.overriddenByAdminId === authUser._id) {
    inputSection = (
      <form onSubmit={handleSendMessage}>
        <input type="text" placeholder="Escreva uma mensagem..." value={message} onChange={handleInputChange} />
        <button type="submit" disabled={!message.trim()}>
          {BUTTON_TEXT_CONSTANTS.SEND_MESSAGE} <FiSend />
        </button>
      </form>
    );
  } else {
    inputSection = (
      <button onClick={handleOverride} className="admin-message-container__override">
        {BUTTON_TEXT_CONSTANTS.ASSUME_CONVERSATION}
      </button>
    );
  }

  return (
    <div className="admin-message-container">
      <div className="admin-message-container__header">
        <span className="admin-message-container__header__conversation-id">
          Conversation ID:
          <br />
          {selectedConversation._id}
        </span>
        {authUser?.isSuperAdmin && (
          <button className="admin-message-container__logs" onClick={() => setShowLogsPopup(true)} title="View Conversation Logs">
            Verificar Relatório
            <AiOutlineFileText size={24} />
          </button>
        )}
        {!selectedConversation.closed && (authUser?.canCloseConversations || authUser?.isSuperAdmin) && (
          <button onClick={handleCloseConversation} className="admin-message-container__close">
            Fechar Conversa
            <FiXCircle />
          </button>
        )}
        {authUser?.isSuperAdmin && (
          <button onClick={handleUnderReview} className="admin-message-container__under-review">
            Colocar em Análise
            <FiAlertTriangle />
          </button>
        )}
      </div>

      <AdminMessages messages={messages || []} />

      {isUserTyping && <div className="admin-message-container__typing-indicator">O utilizador está a escrever{typingIndicator}</div>}

      <div className="admin-message-container__input">{inputSection}</div>

      {showLogsPopup && <LogsPopup onClose={() => setShowLogsPopup(false)} conversationId={selectedConversation._id} />}

      {showAdminReviewPopup && <AdminReviewPopup onClose={() => setShowAdminReviewPopup(false)} conversationId={selectedConversation._id} />}
    </div>
  );
};

export default AdminMessageContainer;
