import React from "react";
import { FiMessageSquare } from "react-icons/fi";
import "./QuestionAnswerIcon.scss";

const QuestionAnswerIcon = ({ onClick }) => {
  return (
    <button className="qa-icon" onClick={onClick} title="Manage Q&A">
      <FiMessageSquare size={24} />
    </button>
  );
};

export default QuestionAnswerIcon;
