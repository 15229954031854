//******************************************************************************************
// Chatbox Component: Main chat window for chatbot users, displaying messages and indicating
// if the user is chatting with the Bot or an Admin. Allows resetting the conversation and
// toggling the chat visibility.
//******************************************************************************************

import React, { useEffect, useState } from "react";
import MessageContainer from "./message/MessageContainer";
import "./Chatbox.scss";
import { ChatbotUserMessagesContextProvider, useChatbotUserMessagesContext } from "../../context/ChatbotUserMessagesContext";
import { CHATBOX_TEXT_CONSTANTS, SPEAKER_NAME_CONSTANTS } from "../../utils/constants";

import { ChatbotSocketProvider } from "../../context/ChatbotSocketContext";

const Chatbox = () => {
  return (
    <ChatbotUserMessagesContextProvider>
      <ChatboxContent />
    </ChatbotUserMessagesContextProvider>
  );
};

const ChatboxContent = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [refreshConversationFn, setRefreshConversationFn] = useState(null);
  const toggleChatbox = () => setIsOpen(!isOpen);

  const { isOverridden, isClosed, token, messages, botActive, isWaitingForHuman } = useChatbotUserMessagesContext();

  // Function to trigger a new conversation
  const handleResetChat = () => {
    if (refreshConversationFn) {
      refreshConversationFn();
    } else {
      console.error("Refresh function not set");
    }
  };
  // Dynamically calculate conversation partner and icon
  let conversationPartner = "";
  let conversationIcon = null;

  if (isWaitingForHuman) {
    // Talking to human
    conversationPartner = "Esperando Atendimento";
    conversationIcon = null;
  } else if (isOverridden) {
    // Waiting for agent
    conversationPartner = SPEAKER_NAME_CONSTANTS.ADMIN;
    conversationIcon = (
      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path d="M622.3 271.1l-115.2-45c-4.1-1.6-12.6-3.7-22.2 0l-115.2 45c-10.7 4.2-17.7 14-17.7 24.9 0 111.6 68.7 188.8 132.9 213.9 9.6 3.7 18 1.6 22.2 0C558.4 489.9 640 420.5 640 296c0-10.9-7-20.7-17.7-24.9zM496 462.4V273.3l95.5 37.3c-5.6 87.1-60.9 135.4-95.5 151.8zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm96 40c0-2.5.8-4.8 1.1-7.2-2.5-.1-4.9-.8-7.5-.8h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c6.8 0 13.3-1.5 19.2-4-54-42.9-99.2-116.7-99.2-212z"></path>
      </svg>
    );
  } else if (botActive) {
    // Talking to bot
    conversationPartner = SPEAKER_NAME_CONSTANTS.BOT;
    conversationIcon = (
      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path>
      </svg>
    );
  } else {
    // Bot is not active and not waiting for human
    conversationPartner = "";
    conversationIcon = null;
  }

  return (
    <div className={`chatbox ${isOpen ? "chatbox--open" : "chatbox--closed"}`}>
      <div className="chatbox__header">
        <h2>
          {conversationIcon}
          {"   "}
          {conversationPartner}
        </h2>
        <div className="chatbox__header-buttons">
          {token && isOpen && (
            <button className="chatbox__reset-button" type="button" onClick={handleResetChat}>
              Reiniciar Conversa
            </button>
          )}
          <button className="chatbox__toggle-button" type="button" onClick={toggleChatbox}>
            {isOpen ? "−" : "+"}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="chatbox__container">
          <ChatbotSocketProvider>
            <MessageContainer setRefreshConversationFn={setRefreshConversationFn} />
          </ChatbotSocketProvider>
        </div>
      )}
    </div>
  );
};

export default Chatbox;
