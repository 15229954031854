import React from "react";
import { FiUser } from "react-icons/fi";
import "./UserIcon.scss";

const UserIcon = ({ onClick }) => {
  return (
    <button className="user-icon" onClick={onClick} title="User Management">
      <FiUser size={24} />
    </button>
  );
};

export default UserIcon;
