//******************************************************************************************
// useLogin Hook: Manages the login process by authenticating with the backend API, storing
// user data in local storage, and updating the authentication context. Returns a `login`
// function for form handling and a `loading` state to track login progress.
//******************************************************************************************

import React from "react";
import { useAuthContext } from "../context/AuthContext";

const useLogin = () => {
  const [loading, setLoading] = React.useState(false);
  const { setAuthUser } = useAuthContext();
  const login = async (username, password) => {
    setLoading(true);
    try {
      const endpoint = "/api/admin/login";

      const apiUrl = new URL(endpoint, process.env.REACT_APP_API_URL).toString();
      const res = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(errorText);
      }
      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      const user = {
        _id: data._id,
        username: data.username,
        isSuperAdmin: data.isSuperAdmin,
        canManageFAQ: data.canManageFAQ,
        canCloseConversations: data.canCloseConversations,
        canSeeClosed: data.canSeeClosed,
        canSeeOverriddenByOthers: data.canSeeOverriddenByOthers,
      };

      setAuthUser(user, data.token);
      return true;
    } catch (error) {
      console.log("Error:", error.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { loading, login };
};
export default useLogin;
