import React from "react";
import Login from "./pages/login/Login.jsx";
import Homepage from "./pages/homepage/Home.jsx";
import Cms from "./pages/cms/cms.jsx";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "./context/AuthContext";

const App = () => {
  const { authUser } = useAuthContext();

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />} />

        <Route path="/cms" element={authUser ? <Cms /> : <Navigate to="/login" replace />} />

        <Route path="/login" element={!authUser ? <Login /> : <Navigate to="/cms" replace />} />
      </Routes>
    </div>
  );
};

export default App;
