//******************************************************************************************
// AdminMessagesContextProvider: Manages state and actions for admin interactions with user
// conversations. Listens to socket events for real-time updates on conversations, handles
// message fetching, sending, and dynamic room joining for Botpress conversation management.
//******************************************************************************************

import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import { useAdminSocket } from "./AdminSocketContext";
import { useAuthContext } from "./AuthContext";

// Create context for managing admin messages and conversation states across components
export const AdminMessagesContext = createContext();

// Custom hook to access the AdminMessagesContext in components
export const useAdminMessagesContext = () => {
  return useContext(AdminMessagesContext);
};

export const AdminMessagesContextProvider = ({ children }) => {
  const { socket, isConnected } = useAdminSocket();
  const { authUser, handleExpiredToken } = useAuthContext();

  const [messages, setMessages] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [BotRoomId, setBotRoomId] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const conversationsRef = useRef([]);
  const canCloseConversations = authUser?.canCloseConversations || false;
  const canViewClosed = authUser?.canSeeClosed || false;

  const fetchConversations = async () => {
    try {
      const token = localStorage.getItem("admin-token");
      let endpoint = "/api/admin/conversations";

      // Include date parameters if SuperAdmin and either startDate or endDate is provided
      if (authUser?.isSuperAdmin) {
        const params = new URLSearchParams();
        if (startDate) {
          params.append("startDate", startDate);
        }
        if (endDate) {
          params.append("endDate", endDate);
        }
        if (params.toString()) {
          endpoint += `?${params.toString()}`;
        }
      }

      const baseUrl = process.env.REACT_APP_API_URL;
      const apiUrl = new URL(endpoint, baseUrl).toString();

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (data.success) {
        setConversations(data.conversations);
      } else {
        console.error("Failed to fetch conversations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching conversations:", error);
    }
  };

  useEffect(() => {
    if (authUser) {
      fetchConversations();
    }
  }, [authUser, startDate, endDate]);

  useEffect(() => {
    if (selectedConversation && selectedConversation.botRoomId) {
      setBotRoomId(selectedConversation.botRoomId);
    }
  }, [selectedConversation]);

  const markConversationAsReadLocally = (conversationId, adminId) => {
    if (authUser?._id !== adminId) return;
    setConversations((prev) => prev.map((conv) => (conv._id === conversationId ? { ...conv, adminSeen: true } : conv)));
    if (selectedConversation?._id === conversationId) {
      setSelectedConversation((prev) => (prev ? { ...prev, adminSeen: true } : prev));
    }
  };

  // Function to select a conversation by ID
  const selectConversationById = async (conversationId) => {
    try {
      const token = localStorage.getItem("admin-token");
      const apiUrl = new URL(`/api/config/conversations/${conversationId}`, process.env.REACT_APP_API_URL).toString();

      const response = await fetch(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        handleExpiredToken();
        return false;
      }

      if (response.status === 404) {
        console.error("Conversation not found");
        return false;
      }

      const data = await response.json();
      if (response.ok && data.success) {
        setSelectedConversation(data.conversation);

        // Mark the conversation as read locally
        setConversations((prev) => prev.map((conv) => (conv._id === conversationId ? { ...conv, adminSeen: true } : conv)));
        return true;
      } else {
        console.error("Failed to fetch conversation details:", data.message);
        return false;
      }
    } catch (error) {
      console.error("Error fetching conversation details:", error);
      return false;
    }
  };

  // Listen for "conversationCreated" events from the socket to dynamically add new conversations
  useEffect(() => {
    if (!socket || !isConnected) {
      console.log("Socket is not initialized yet.");
      return;
    }

    console.log("Socket is initialized and ready to receive events.");

    const handleConversationCreated = (newConversation) => {
      console.log("New conversation created:", newConversation);
      setConversations((prev) => {
        const updated = [...prev, newConversation];
        conversationsRef.current = updated;
        return updated;
      });
    };

    socket.on("conversationCreated", handleConversationCreated);
    return () => {
      socket.off("conversationCreated", handleConversationCreated);
    };
  }, [socket, isConnected]);

  // Fetch all conversations when the component mounts or the authenticated user changes
  useEffect(() => {
    const fetchConversationsOnAuth = async () => {
      if (!authUser) return;
      await fetchConversations();
    };
    fetchConversationsOnAuth();
  }, [authUser]);

  // Listen for "conversationUpdated" events to update conversation state in real-time
  useEffect(() => {
    if (!socket || !isConnected) return;

    const handleConversationUpdated = (updatedConversation) => {
      console.log("Conversation updated:", updatedConversation);

      setConversations((prev) => prev.map((conv) => (conv._id === updatedConversation._id ? updatedConversation : conv)));

      if (selectedConversation && selectedConversation._id === updatedConversation._id) {
        setSelectedConversation(updatedConversation);
        setMessages(updatedConversation.messages || []);
      }

      if (
        updatedConversation.overriddenByAdminId === authUser?._id ||
        updatedConversation.closedBy === authUser?._id ||
        (updatedConversation.messages && updatedConversation.messages.some((msg) => msg.senderModel === "Admin" && msg.senderId === authUser?._id))
      ) {
        markConversationAsReadLocally(updatedConversation._id, authUser?._id);
      }
    };

    socket.on("conversationUpdated", handleConversationUpdated);
    return () => {
      socket.off("conversationUpdated", handleConversationUpdated);
    };
  }, [socket, isConnected, selectedConversation, authUser]);

  // Fetch messages for the selected conversation when it changes
  useEffect(() => {
    if (!selectedConversation) return;

    const fetchMessages = async () => {
      const token = localStorage.getItem("admin-token");
      const endpoint = `/api/admin/messages/${selectedConversation._id}/`;
      const apiUrl = new URL(endpoint, process.env.REACT_APP_API_URL).toString();

      console.log("Fetching messages for conversation:", selectedConversation._id);
      try {
        const response = await fetch(apiUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const data = await response.json();
        if (response.ok) {
          console.log("Fetched messages:", data.messages);
          setMessages(data.messages);

          // Only update selectedConversation if we don't have botRoomId yet
          setSelectedConversation((prev) => {
            if (!prev) return prev;
            if (!prev.botRoomId && data.conversation && data.conversation.botRoomId) {
              return { ...prev, botRoomId: data.conversation.botRoomId };
            }
            return prev;
          });
        } else {
          if (response.status === 401) {
            handleExpiredToken();
          }
          console.error("Failed to fetch messages:", data.message);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [selectedConversation, handleExpiredToken]);

  // Listen for new messages in the selected conversation in real-time
  useEffect(() => {
    if (!socket) return;

    const handleNewMessage = (newMessage) => {
      if (selectedConversation && newMessage.conversationId === selectedConversation._id) {
        console.log("New message for the selected conversation:", newMessage);
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    };

    socket.on("newMessage", handleNewMessage);
    return () => {
      socket.off("newMessage", handleNewMessage);
    };
  }, [socket, selectedConversation]);

  return (
    <AdminMessagesContext.Provider
      value={{
        messages,
        setMessages,
        selectedConversation,
        setSelectedConversation,
        selectConversationById,
        conversations,
        setConversations,
        canCloseConversations,
        BotRoomId,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        fetchConversations,
        canViewClosed,
      }}
    >
      {children}
    </AdminMessagesContext.Provider>
  );
};
