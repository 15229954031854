//******************************************************************************************
// Login Page: Handles the login process for admins, state management for username and password, and error handling. Integrates with a custom
// login hook and navigates to the Admin Pannel upon successful login.
//******************************************************************************************

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useLogin from "../../hooks/useLogin";
import "./Login.scss";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { loading, login } = useLogin();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    try {
      const success = await login(username, password);

      if (success) {
        navigate("/cms");
      } else {
        setErrorMessage("Credenciais inválidas. Por favor, tente novamente.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("Ocorreu um erro inesperado. Por favor, tente novamente.");
    }
  };

  return (
    <div className="login">
      <div className="login__container">
        <h2 className="login__title">Painel Chatbot</h2>
        <div className="login__logos">
          <img src="/A360.svg" alt="A360 Logo" className="login__logo__a360" />
          <img src="/mun_trofa.svg" alt="Mun Trofa Logo" className="login__logo__municipal" />
        </div>
        <form className="login__form" onSubmit={handleSubmit}>
          <div className="login__field">
            <label htmlFor="username">Username:</label>
            <input type="text" id="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div className="login__field">
            <label htmlFor="password">Password:</label>
            <input type="password" id="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <button className="login__button" type="submit" disabled={loading}>
            {loading ? "Loading..." : "Login"}
          </button>
          {errorMessage && <div className="login__error">{errorMessage}</div>}
        </form>
      </div>
    </div>
  );
};

export default Login;
