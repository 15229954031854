import React from "react";
import { FiSettings } from "react-icons/fi";
import "./SettingsIcon.scss";

const SettingsIcon = ({ onClick }) => {
  return (
    <button className="settings-icon" onClick={onClick} title="Settings">
      <FiSettings size={24} />
    </button>
  );
};

export default SettingsIcon;
