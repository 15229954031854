import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AdminReviewPopup.scss";

const AdminReviewPopup = ({ onClose, conversationId }) => {
  const [admins, setAdmins] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // Fetch all admins and the currently assigned reviewers when the component mounts
  useEffect(() => {
    const fetchAdminsAndReviewers = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("admin-token");
        const [adminsResponse, reviewersResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/config/admins`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/api/admin/conversations/${conversationId}/reviewers`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        if (adminsResponse.data.success && reviewersResponse.data.success) {
          setAdmins(adminsResponse.data.admins);
          setSelectedAdmins(reviewersResponse.data.reviewers.map((admin) => admin._id));
        } else {
          setMessage("Falha ao Carregar dados.");
        }
      } catch (error) {
        console.error("Falha ao Carregar dados:", error);
        setMessage("Falha ao Carregar dados.");
      } finally {
        setLoading(false);
      }
    };

    fetchAdminsAndReviewers();
  }, [conversationId]);

  const toggleAdminSelection = (adminId) => {
    setSelectedAdmins((prevSelected) => (prevSelected.includes(adminId) ? prevSelected.filter((id) => id !== adminId) : [...prevSelected, adminId]));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("admin-token");
      const apiUrl = new URL(`/api/admin/conversations/${conversationId}/underReview`, process.env.REACT_APP_API_URL).toString();

      const response = await axios.post(
        apiUrl,
        { adminIds: selectedAdmins },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success) {
        setMessage("Conversa colocada em análise.");
        onClose();
      } else {
        setMessage("Falha ao colocar a conversa em analise.");
      }
    } catch (error) {
      console.error("Falha ao colocar a conversa em analise.", error);
      setMessage("Falha ao colocar a conversa em analise.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-review-popup">
      <div className="admin-review-popup__overlay" onClick={onClose}></div>
      <div className="admin-review-popup__content">
        <h2>Selecione os administradores que irão analisar:</h2>
        {message && <p className="admin-review-popup__message">{message}</p>}

        {loading ? (
          <p>A carregar administradores...</p>
        ) : (
          <ul className="admin-review-popup__admin-list">
            {admins.map((admin) => (
              <li key={admin._id}>
                <label>
                  <input type="checkbox" checked={selectedAdmins.includes(admin._id)} onChange={() => toggleAdminSelection(admin._id)} />
                  {admin.username}
                </label>
              </li>
            ))}
          </ul>
        )}

        <div className="admin-review-popup__buttons">
          <button onClick={handleSubmit} disabled={loading}>
            Submeter
          </button>
          <button onClick={onClose} disabled={loading}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminReviewPopup;
