//Names displayed in the admin chat sidebar
export const SPEAKER_NAME_CONSTANTS = {
  CITIZEN: "Cidadão",
  SYSTEM: "Sistema",
  BOT: "BOT",
  ADMIN: "Operador",
};

//Statuses displayed in the admin chat sidebar
export const CONVERSATION_STATUS_CONSTANTS = {
  USER_TALKING_TO_BOT: "Utilizador a falar com o Bot",
  WAITING_FOR_HUMAN_AGENT: "A aguardar por um agente humano",
  TALKING_WITH_YOU: "A falar consigo",
  TALKING_WITH_OTHER_ADMIN: "Utilizador a falar com outro administrador",
  NO_MESSAGES_TO_DISPLAY: "Sem Mensagens para apresentar",
  CLOSED: "Fechado",
  UNDER_REVIEW: "Em revisão",
};

//Text displayed in buttons in the admin chat sidebar
export const BUTTON_TEXT_CONSTANTS = {
  LOGOUT: "Sair",
  SEND_MESSAGE: "Enviar",
  ASSUME_CONVERSATION: "Assumir Conversa",
  CONVERSATION_ASSUMED_BY: "Conversa foi assumida por",
};

// Text constants for the admin pannel
export const DASH_TEXT_CONSTANTS = {
  SELECT_CONVERSATION_PROMPT: "Selecione uma conversa para ver as mensagens.",
  NO_CONVERSATION_PROMPT: "Não há neste momento nenhuma conversa activa",
};

// Text constants for the chatbot interface
export const CHATBOX_TEXT_CONSTANTS = {
  TALKING_WITH: "A falar com",
  LOADING: "A carregar...",
  NO_MESSAGE_CONTENT: "Sem conteúdo da mensagem",
  NO_MESSAGES_YET: "Ainda não há mensagens. Inicie a conversa!",
  ERROR_SENDING_MESSAGE: "Erro ao enviar mensagem",
  TYPE_MESSAGE: "Escreva uma mensagem...",
};
