//******************************************************************************************
// This component is responsible for rendering the list of messages for the selected
// conversation in the admin panel.
//******************************************************************************************

import React, { useEffect, useRef } from "react";
import AdminMessage from "./AdminMessage";
import "./AdminMessages.scss";

const AdminMessages = ({ messages }) => {
  // Ref to track the end of the messages container for smooth scrolling
  const messagesEndRef = useRef(null);
  const prevMessagesLength = useRef(messages.length);

  // Effect to handle scrolling to the bottom when new messages are added
  useEffect(() => {
    if (messages.length > prevMessagesLength.current) {
      console.log("Scrolling to the bottom of messages");
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    prevMessagesLength.current = messages.length;
  }, [messages]);

  return (
    <div className="admin-messages">
      {messages.map((message) => (
        <AdminMessage key={message._id} message={message} />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};
export default AdminMessages;
