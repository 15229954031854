//******************************************************************************************
// Admin Home Page: This page serves as the main dashboard for managing conversations.
// It renders the AdminDashboard component, where admins can oversee and control user
// interactions, messages, and other relevant conversation data.
//******************************************************************************************

import React from "react";
import AdminDashboard from "../../components/admin/AdminDashboard";
import "./cms.scss";

const Home = () => {
  return (
    <div className="admin-home">
      <AdminDashboard />
    </div>
  );
};

export default Home;
