import React from "react";
import { FaKey } from "react-icons/fa";
import "./KeyIcon.scss";

const KeyIcon = ({ onClick, username }) => {
  return (
    <button className="key-icon" onClick={onClick} title={`Alterar Password ${username || "Admin"}`}>
      <FaKey />
    </button>
  );
};

export default KeyIcon;
