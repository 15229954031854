//******************************************************************************************
// ChatbotUserMessagesContextProvider: Provides and manages state for chatbot user messages
// within a conversation. Utilizes a socket connection to listen for new messages and
// conversation updates, and shares state across components via context.
//******************************************************************************************

import React, { createContext, useContext, useState, useEffect } from "react";
import { useChatbotSocket } from "./ChatbotSocketContext";

export const ChatbotUserMessagesContext = createContext();

// Custom hook to access the ChatbotUserMessagesContext
export const useChatbotUserMessagesContext = () => {
  return useContext(ChatbotUserMessagesContext);
};

export const ChatbotUserMessagesContextProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [conversationId, setConversationId] = useState(localStorage.getItem("chatbot-conversationId"));
  const [token, setToken] = useState(localStorage.getItem("chatbot-token"));
  const [isOverridden, setIsOverridden] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const socket = useChatbotSocket();
  const [botActive, setBotActive] = useState(false);
  const [isWaitingForHuman, setIsWaitingForHuman] = useState(false);
  useEffect(() => {
    if (token) {
      localStorage.setItem("chatbot-token", token);
    } else {
      localStorage.removeItem("chatbot-token");
    }

    if (conversationId) {
      localStorage.setItem("chatbot-conversationId", conversationId);
    } else {
      localStorage.removeItem("chatbot-conversationId");
    }
  }, [token, conversationId]);

  useEffect(() => {
    if (!socket || !conversationId) return;

    socket.emit("joinConversation", conversationId);

    socket.on("newMessage", (newMessage) => {
      if (newMessage.conversationId === conversationId) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    });

    return () => {
      socket.emit("leaveConversation", conversationId);
      socket.off("newMessage");
      socket.off("conversationUpdated");
    };
  }, [socket, conversationId]);

  return (
    <ChatbotUserMessagesContext.Provider
      value={{
        messages,
        setMessages,
        conversationId,
        setConversationId,
        token,
        setToken,
        isOverridden,
        isClosed,
        setIsClosed,
        setIsOverridden,
        botActive,
        setBotActive,
        isWaitingForHuman,
        setIsWaitingForHuman,
      }}
    >
      {children}
    </ChatbotUserMessagesContext.Provider>
  );
};
