//******************************************************************************************
// SettingsPopup Component: Displays and manages configuration settings for the application.
// Allows an admin to view, create, and update settings, including toggling bot activation.
//******************************************************************************************

import React, { useState, useEffect } from "react";
import "./SettingsPopup.scss";
import axios from "axios";
import { useAuthContext } from "../../../context/AuthContext";

const SettingsPopup = ({ onClose }) => {
  const { authUser } = useAuthContext();
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [confirmationState, setConfirmationState] = useState({}); // State for confirmation logic

  const [holidays, setHolidays] = useState([]);
  const [editedHolidays, setEditedHolidays] = useState([]);
  useEffect(() => {
    fetchHolidays();
  }, []);
  // Fetch holidays
  const fetchHolidays = async () => {
    try {
      const token = localStorage.getItem("admin-token");
      const apiUrl = new URL("/api/config/holidays", process.env.REACT_APP_API_URL).toString();

      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.success) {
        const holidays = response.data.holidays.map((holiday) => ({
          ...holiday,
          date: formatDate(holiday.date),
        }));
        setHolidays(holidays);
        setEditedHolidays(holidays);
      }
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };
  const fetchDefaultHolidays = async () => {
    try {
      const token = localStorage.getItem("admin-token");
      const currentYear = new Date().getFullYear();
      const apiUrl = new URL(`/api/config/holidays/${currentYear}`, process.env.REACT_APP_API_URL).toString();

      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("response", response);
      if (response.data.success) {
        const holidays = response.data.holidays.map((holiday) => ({
          ...holiday,
          date: formatDate(holiday.date),
        }));
        setHolidays(holidays);
        setEditedHolidays(holidays);
      }
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };
  // Save edited holidays
  const saveHolidays = async () => {
    try {
      const token = localStorage.getItem("admin-token");
      const apiUrl = new URL("/api/config/holidays", process.env.REACT_APP_API_URL).toString();
      const response = await axios.put(
        apiUrl,
        { holidays: editedHolidays },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        setMessage("Holidays updated successfully.");
        fetchHolidays(); // Refresh data
      } else {
        setMessage("Failed to update holidays.");
      }
    } catch (error) {
      console.error("Error saving holidays:", error);
    }
  };

  // Reset holidays to default
  const resetToDefaultHolidays = async () => {
    try {
      setMessage(""); // Clear messages
      await fetchDefaultHolidays(); // Re-fetch default holidays
      setMessage("Configuração padrão carregada.");
    } catch (error) {
      setMessage("Falha ao carregar configuração padrão.");
    }
  };

  // Update a specific holiday field
  const updateHoliday = (index, field, value) => {
    const updatedHolidays = [...editedHolidays];
    updatedHolidays[index] = { ...updatedHolidays[index], [field]: value };
    setEditedHolidays(updatedHolidays);
  };

  // Categorize holidays

  const getFixedHolidays = () => editedHolidays.filter((holiday) => holiday.type === "F");
  const getVariableHolidays = () => editedHolidays.map((holiday, index) => ({ holiday, index })).filter(({ holiday }) => holiday.type === "V");
  const getLocalHolidays = () => {
    const localHolidays = editedHolidays.map((holiday, index) => ({ holiday, index })).filter(({ holiday }) => holiday?.type === "M");
    return localHolidays;
  };
  const getTolerances = () => editedHolidays.map((holiday, index) => ({ holiday, index })).filter(({ holiday }) => holiday.type === "T");
  const handleAddTolerance = () => {
    const newTolerance = { date: "", name: "", type: "T" };
    setEditedHolidays([...editedHolidays, newTolerance]);
  };
  const formatDate = (date) => {
    if (!date) return "";

    // If date is already in YYYY-MM-DD format, return it
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return date;
    }

    // Parse date and extract components in UTC to avoid timezone issues
    const d = new Date(date);
    if (isNaN(d.getTime())) return "";

    const year = d.getUTCFullYear();
    const month = String(d.getUTCMonth() + 1).padStart(2, "0");
    const day = String(d.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  // Fetch configuration data when component mounts or when `authUser` changes
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const token = localStorage.getItem("admin-token");
        if (!token) {
          console.error("No admin token found.");
          return;
        }

        const apiUrl = new URL("/api/config", process.env.REACT_APP_API_URL).toString();
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          setConfig({
            ...response.data.config,
            workingHours: response.data.config.workingHours || [],
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setConfig(null);
        } else {
          console.error("Error fetching configuration:", error);
        }
      }
    };

    fetchConfig();
  }, [authUser]);

  // Handle confirmation logic for tasks
  const handleExecuteTask = async (taskType) => {
    if (confirmationState[taskType]?.confirmed) {
      // Execute the task
      setLoading(true);
      setMessage("");

      try {
        const token = localStorage.getItem("admin-token");
        const apiUrl = new URL(`/api/tasks/${taskType}`, process.env.REACT_APP_API_URL).toString();

        const response = await axios.post(
          apiUrl,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setMessage(`${taskType === "close" ? "Closing" : "Deletion"} task executed successfully.`);
        } else {
          setMessage(`Failed to execute ${taskType === "close" ? "closing" : "deletion"} task.`);
        }
      } catch (error) {
        console.error(`Error executing ${taskType} task:`, error);
        setMessage(`Failed to execute ${taskType === "close" ? "closing" : "deletion"} task.`);
      }

      setLoading(false);
      // Reset confirmation state
      setConfirmationState((prevState) => ({ ...prevState, [taskType]: null }));
    } else {
      // Start countdown
      startCountdown(taskType);
    }
  };

  const startCountdown = (taskType) => {
    setConfirmationState((prevState) => ({
      ...prevState,
      [taskType]: { countdown: 3, confirmed: false },
    }));

    const countdownInterval = setInterval(() => {
      setConfirmationState((prevState) => {
        const currentCountdown = prevState[taskType]?.countdown || 0;
        if (currentCountdown <= 1) {
          clearInterval(countdownInterval);
          return {
            ...prevState,
            [taskType]: { countdown: 0, confirmed: true },
          };
        }
        return {
          ...prevState,
          [taskType]: { countdown: currentCountdown - 1, confirmed: false },
        };
      });
    }, 1000);
  };

  // Handle form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
  };

  // Convert decimal time to HH:mm format for display
  const decimalTimeToHHMM = (decimalTime) => {
    const hours = Math.floor(decimalTime);
    const minutes = Math.round((decimalTime - hours) * 60);
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };

  // Convert HH:mm format to decimal time
  const hhmmToDecimalTime = (hhmm) => {
    const [hours, minutes] = hhmm.split(":").map(Number);
    return hours + minutes / 60;
  };

  // Handle time input changes
  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    const decimalTime = hhmmToDecimalTime(value);
    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: decimalTime,
    }));
  };

  // Handle changes to the working hours inputs
  const handleWorkingHoursChange = (e, index, position) => {
    const value = hhmmToDecimalTime(e.target.value);
    setConfig((prevConfig) => {
      const newWorkingHours = [...(prevConfig.workingHours || [])];
      newWorkingHours[index][position] = value;
      return {
        ...prevConfig,
        workingHours: newWorkingHours,
      };
    });
  };

  // Add a new time range
  const addTimeRange = () => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      workingHours: [...(prevConfig.workingHours || []), [8.0, 17.0]], // Default time range
    }));
  };

  // Remove a time range
  const removeTimeRange = (index) => {
    setConfig((prevConfig) => {
      const newWorkingHours = (prevConfig.workingHours || []).filter((_, i) => i !== index);
      return {
        ...prevConfig,
        workingHours: newWorkingHours,
      };
    });
  };

  // Update configuration
  const handleUpdateConfig = async () => {
    setLoading(true);
    setMessage("");

    try {
      const token = localStorage.getItem("admin-token");
      if (!token) {
        console.error("No admin token found.");
        setLoading(false);
        return;
      }

      const apiUrl = new URL("/api/config", process.env.REACT_APP_API_URL).toString();
      const response = await axios.put(apiUrl, config, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setMessage("Configurações alteradas com sucesso");
      } else {
        setMessage("Falha ao alterar configurações.");
      }
    } catch (error) {
      console.error("Error updating configuration:", error);
      setMessage("Falha ao alterar configurações.");
    }

    setLoading(false);
  };

  const handleRemoveHoliday = (index) => {
    const updatedHolidays = editedHolidays.filter((_, i) => i !== index);
    setEditedHolidays(updatedHolidays);
  };

  // Create default configuration
  const handleCreateDefaultConfig = async () => {
    setLoading(true);
    setMessage("");

    try {
      const token = localStorage.getItem("admin-token");
      if (!token) {
        console.error("No admin token found.");
        setLoading(false);
        return;
      }

      const apiUrl = new URL("/api/config/create", process.env.REACT_APP_API_URL).toString();
      const response = await axios.post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setConfig(response.data.config);
        setMessage("Default configuration created successfully.");
      }
    } catch (error) {
      console.error("Error creating configuration:", error);
      setMessage("Failed to create configuration.");
    }

    setLoading(false);
  };

  return (
    <div className="settings-popup">
      <div className="settings-popup__overlay" onClick={onClose}></div>
      <div className="settings-popup__content">
        <h2>Configurações</h2>

        {message && <p className="settings-popup__message">{message}</p>}

        {config === null ? (
          <button onClick={handleCreateDefaultConfig} disabled={loading} className="settings-popup__create-button">
            {loading ? "Criando..." : "Criar Configuração Padrão"}
          </button>
        ) : (
          <div className="settings-popup__form">
            <div className="settings-popup__form-group">
              <label>Horário de Funcionamento:</label>
              {config.workingHours && config.workingHours.length > 0 ? (
                config.workingHours.map((timeRange, index) => (
                  <div key={index} className="settings-popup__time-range">
                    <input type="time" value={decimalTimeToHHMM(timeRange[0])} onChange={(e) => handleWorkingHoursChange(e, index, 0)} />
                    <span> - </span>
                    <input type="time" value={decimalTimeToHHMM(timeRange[1])} onChange={(e) => handleWorkingHoursChange(e, index, 1)} />
                    <button onClick={() => removeTimeRange(index)}>Remover</button>
                  </div>
                ))
              ) : (
                <p>Nenhum horário de funcionamento definido.</p>
              )}
              <button onClick={addTimeRange} className="add-time-range">
                Adicionar Horário
              </button>
            </div>

            {/* Other settings form elements */}
            <div className="settings-popup__form-group settings-popup__checkbox-group">
              <label>Bot Ativo:</label>
              <input
                type="checkbox"
                name="botActive"
                checked={config.botActive || false}
                onChange={(e) => setConfig({ ...config, botActive: e.target.checked })}
              />
            </div>

            <div className="settings-popup__form-group">
              <label>Hora de Fecho Automático de Conversas:</label>
              <div className="settings-popup__input-group">
                <input type="time" name="closingTime" value={decimalTimeToHHMM(config.closingTime || 17.5)} onChange={handleTimeChange} />
                {confirmationState["close"]?.countdown > 0 ? (
                  <button disabled className="settings-popup__execute-button countdown">
                    Confirmar ({confirmationState["close"].countdown})
                  </button>
                ) : confirmationState["close"]?.confirmed ? (
                  <button onClick={() => handleExecuteTask("close")} disabled={loading} className="settings-popup__execute-button confirm">
                    Confirmar
                  </button>
                ) : (
                  <button onClick={() => handleExecuteTask("close")} disabled={loading} className="settings-popup__execute-button">
                    Executar agora
                  </button>
                )}
              </div>
            </div>

            <div className="settings-popup__form-group">
              <label>Hora de Remoção Automática de Conversas:</label>
              <div className="settings-popup__input-group">
                <input type="time" name="deletionTime" value={decimalTimeToHHMM(config.deletionTime || 18.0)} onChange={handleTimeChange} />
                {confirmationState["delete"]?.countdown > 0 ? (
                  <button disabled className="settings-popup__execute-button countdown">
                    Confirmar ({confirmationState["delete"].countdown})
                  </button>
                ) : confirmationState["delete"]?.confirmed ? (
                  <button onClick={() => handleExecuteTask("delete")} disabled={loading} className="settings-popup__execute-button confirm">
                    Confirmar
                  </button>
                ) : (
                  <button onClick={() => handleExecuteTask("delete")} disabled={loading} className="settings-popup__execute-button">
                    Executar agora
                  </button>
                )}
              </div>
            </div>

            <div className="settings-popup__buttons">
              <button onClick={handleUpdateConfig} disabled={loading} className="update-button">
                {loading ? "Atualizando..." : "Alterar configurações"}
              </button>
            </div>
            <hr className="settings-popup__divider" />
            <h2>Configurações de Feriados</h2>
            <div className="settings-popup__holiday-buttons">
              <button onClick={resetToDefaultHolidays} className="change-button">
                Obter Configuração Padrão
              </button>
            </div>

            <h3>Tolerâncias de Ponto</h3>
            <div className="settings-popup__holiday-buttons">
              <button onClick={() => handleAddTolerance()} className="tolerance-button">
                Adicionar Tolerância
              </button>
            </div>
            <ul>
              {getTolerances().map(({ holiday, index }) => (
                <li key={index}>
                  <input type="date" value={formatDate(holiday.date)} onChange={(e) => updateHoliday(index, "date", e.target.value)} />
                  <input type="text" value={holiday.name || ""} onChange={(e) => updateHoliday(index, "name", e.target.value)} placeholder="Descrição" />
                  <select value={holiday.type} onChange={(e) => updateHoliday(index, "type", e.target.value)}>
                    <option value="T">Tolerância</option>
                    <option value="F">Feriado Fixo</option>
                    <option value="V">Feriado Variável</option>
                    <option value="M">Feriado Municipal</option>
                  </select>
                  <button onClick={() => handleRemoveHoliday(index)}>Remover</button>
                </li>
              ))}
            </ul>

            <h3>Feriados Fixos</h3>
            <ul>
              {getFixedHolidays().map((holiday, index) => (
                <li key={index}>
                  <input type="date" value={formatDate(holiday.date)} onChange={(e) => updateHoliday(index, "date", e.target.value)} />
                  <input type="text" value={holiday.name || ""} onChange={(e) => updateHoliday(index, "name", e.target.value)} placeholder="Nome" />
                  <select value={holiday.type} onChange={(e) => updateHoliday(index, "type", e.target.value)}>
                    <option value="F">Feriado Fixo</option>
                    <option value="V">Feriado Variável</option>
                    <option value="M">Feriado Municipal</option>
                    <option value="T">Tolerância</option>
                  </select>
                  <button onClick={() => handleRemoveHoliday(index)}>Remover</button>
                </li>
              ))}
            </ul>

            <h3>Feriados Variáveis</h3>
            <ul>
              {getVariableHolidays().map(({ holiday, index }) => (
                <li key={index}>
                  <input type="date" value={formatDate(holiday.date)} onChange={(e) => updateHoliday(index, "date", e.target.value)} />
                  <input type="text" value={holiday.name} onChange={(e) => updateHoliday(index, "name", e.target.value)} placeholder="Nome" />
                  <select value={holiday.type} onChange={(e) => updateHoliday(index, "type", e.target.value)}>
                    <option value="V">Feriado Variável</option>
                    <option value="F">Feriado Fixo</option>
                    <option value="M">Feriado Municipal</option>
                    <option value="T">Tolerância</option>
                  </select>
                  <button onClick={() => handleRemoveHoliday(index)}>Remover</button>
                </li>
              ))}
            </ul>

            <h3>Feriados Locais</h3>
            <ul>
              {getLocalHolidays().map(({ holiday, index }) => (
                <li key={index}>
                  <input
                    type="date"
                    value={formatDate(holiday.date)}
                    onChange={(e) => updateHoliday(index, "date", e.target.value)} // Use original index
                  />
                  <input
                    type="text"
                    value={holiday.name}
                    onChange={(e) => updateHoliday(index, "name", e.target.value)} // Use original index
                    placeholder="Nome"
                  />
                  <select
                    value={holiday.type}
                    onChange={(e) => updateHoliday(index, "type", e.target.value)} // Use original index
                  >
                    <option value="M">Feriado Municipal</option>
                    <option value="F">Feriado Fixo</option>
                    <option value="V">Feriado Variável</option>
                    <option value="T">Tolerância</option>
                  </select>
                  <button onClick={() => handleRemoveHoliday(index)}>Remover</button>
                </li>
              ))}
            </ul>
            <button onClick={saveHolidays} className="save-button">
              Guardar Alterações Dias Especiais
            </button>
            {message && <label className="settings-popup__save-label">{message}</label>}
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsPopup;
